import { Col, Form, InputNumber, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  closeModal,
  isEditOrderMaterialModalOpenState,
  selectedOrderMaterialState,
  setSelectedOrderMaterial,
} from '@store/orders';
import { useUpdateOrderMaterial } from '@api/orders/useUpdateOrderMaterial';
import { useEffect } from 'react';
import { useGetOrderMaterials } from '@api/orders/useGetOrderMaterials';

export function EditOrderMaterialModal() {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const open = useAppSelector(isEditOrderMaterialModalOpenState);
  const selectedOrderMaterial = useAppSelector(selectedOrderMaterialState);

  const { mutate, isSuccess, reset } = useUpdateOrderMaterial();
  const { refetch } = useGetOrderMaterials(selectedOrderMaterial?.orderId ?? '');

  const onOk = () => {
    mutate({ ...selectedOrderMaterial, ...form.getFieldsValue() });
  };

  const onCancel = () => {
    dispatch(closeModal('editOrderMaterial'));
    dispatch(setSelectedOrderMaterial(null));
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue(selectedOrderMaterial);
  }, [selectedOrderMaterial]);

  useEffect(() => {
    if (isSuccess) {
      reset();
      refetch();
      dispatch(closeModal('editOrderMaterial'));
      dispatch(setSelectedOrderMaterial(null));
    }
  }, [isSuccess]);

  return (
    <Modal title="Modifica Materiali" open={open} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="edit-order-material" layout="vertical">
          <Form.Item name="quantity" label="Quantità">
            <InputNumber min={1} style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Col>
    </Modal>
  );
}
