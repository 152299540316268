import { Alert, Col, Form, Input, Modal } from 'antd';
import { useAddWarehouseMaterial, useGetWarehouseMaterials } from '@api/warehouses';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { closeModal, isAddWarehouseMaterialModalOpenState, selectedWarehouseIdState } from '@store/warehouse';

export interface AddWarehouseMaterialModalProps {
  onSuccess?: () => void;
}

export function AddWarehouseMaterialModal({ onSuccess }: AddWarehouseMaterialModalProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const open = useAppSelector(isAddWarehouseMaterialModalOpenState);
  const warehouseId = useAppSelector(selectedWarehouseIdState);

  const { mutate: addWarehouseMaterial, isSuccess, reset, error } = useAddWarehouseMaterial();

  const onOk = () => {
    form.validateFields()
      .then(() => {
        addWarehouseMaterial({ warehouseId, description: form.getFieldValue('description') });
      })
      .catch(() => {
      });
  };

  const onCancel = () => {
    form.resetFields();
    dispatch(closeModal('addMaterial'));
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      reset();

      if (typeof onSuccess === 'function') {
        onSuccess();
      }

      dispatch(closeModal('addMaterial'));
    }
  }, [isSuccess]);

  return (
    <Modal title="Aggiungi Materiale" open={open} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="add-material" layout="vertical">
          <Form.Item
            name="description"
            label="Descrizione"
            rules={[{ required: true, message: 'Per favore inserisci una descrizione' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-referrer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
