import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';


interface IotModals {
  newDevice: boolean;
}

interface IotState {
  modals: IotModals;
}

const initialState: IotState = {
  modals: {
    newDevice: false,
  },
};

export const iotSlice = createSlice({
  name: 'iot',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = true;
    },
    closeModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = false;
    },
  },
});

export const { openModal, closeModal } = iotSlice.actions;

export const isNewDeviceModalOpenState = (state: RootState) => state.iot.modals.newDevice;

export default iotSlice.reducer;
