import { Button, Card, Col, Row, Table } from 'antd';
import { useAppDispatch } from '@hooks/redux';
import { openModal } from '@store/iot';
import { useGetDevices } from '@api/iot';
import { NewDeviceModal } from './components/NewDeviceModal';
import { ColumnProps } from 'antd/es/table';
import { IotDevice } from '@api/types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export function Devices() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data: devices } = useGetDevices();

  console.log('devices', devices);

  const onNewDevice = () => {
    dispatch(openModal('newDevice'));
  };

  const columns: ColumnProps<IotDevice>[] = [
    {
      title: 'Mac Address',
      dataIndex: 'mac',
      key: 'mac',
    },
    {
      title: 'IP Address',
      dataIndex: 'ip',
      key: 'ip',
    },
  ];

  return (
    <Row>
      <Col xs={24}>
        <Card title="Dispositivi IoT" extra={<Button type="primary" onClick={onNewDevice}>Nuovo Device</Button>}>
          <Table
            columns={columns}
            dataSource={devices}
            onRow={(row) => {
              return {
                onClick: () => navigate(`/settings/iot-devices/${row.id}`),
                style: { cursor: 'pointer' },
              };
            }}
            pagination={{
              pageSizeOptions: ['10', '20', '30', '50'],
              responsive: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
              total: devices?.length ?? 0,
            }}
            rowKey="id"
          />
        </Card>
      </Col>
      <NewDeviceModal />
    </Row>
  );
}
