import React, { useEffect } from 'react';
import { Alert, Col, Modal, Typography } from 'antd';
import { useGetSuppliers } from '@api/suppliers/useGetSuppliers';
import { useDeleteSupplier } from '@api/suppliers/useDeleteSupplier';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  closeModal,
  isDeleteSupplierModalOpenState,
  selectedSupplierState,
  setSelectedSupplier,
} from '@store/suppliers';
import { useNavigate } from 'react-router-dom';

export function DeleteSupplierModal() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const open = useAppSelector(isDeleteSupplierModalOpenState);
  const supplier = useAppSelector(selectedSupplierState);

  const { refetch } = useGetSuppliers();
  const { mutate, isSuccess, error, reset } = useDeleteSupplier();

  useEffect(() => {
    if (isSuccess) {
      refetch().then(() => {
        reset();
        dispatch(closeModal('deleteSupplier'));
        navigate('/registry/suppliers');
      });
    }
  }, [isSuccess]);

  const onOk = () => {
    if (supplier) {
      mutate(supplier.id);
    }
  };

  const onCancel = () => {
    reset();
    dispatch(setSelectedSupplier(null));
    dispatch(closeModal('deleteSupplier'));
  };

  return (
    <Modal title="Elimina Fornitore" open={open} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Typography>Sei sicuro di voler eliminare il seguente fornitore?</Typography>

        <Typography.Title level={5} style={{ textAlign: 'center' }}>"{supplier?.name}"</Typography.Title>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-customer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
