import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { simplePost, useSimpleQuery } from '@api/utils';
import {
  ActivateAllWorkPhasesBody,
  AddCommissionBody,
  UpdatePhaseBody,
  WorkOrder,
  WorkOrderCommissionPhaseBody,
  WorkOrderPhaseCommission,
} from '@api/types';

export function useGetWorkOrders() {
  return useSimpleQuery<WorkOrder[]>(
    [QueryKey.GetWorkOrders],
    `${apiBaseUrl}/${ApiPath.WorkOrders}`,
  );
}

export function useGetWorkOrderById(id: string) {
  return useSimpleQuery<WorkOrder>(
    [QueryKey.GetWorkOrderById, id],
    `${apiBaseUrl}/${ApiPath.WorkOrders}/${id}`,
  );
}

export function useCreateWorkOrder() {
  return useMutation({
    mutationKey: [QueryKey.CreateWorkOrder],
    mutationFn: () => simplePost<WorkOrder>(`${apiBaseUrl}/${ApiPath.WorkOrders}`),
  });
}

export function useAddCommission() {
  return useMutation({
    mutationKey: [QueryKey.WorkOrderAddCommission],
    mutationFn: (body: AddCommissionBody) =>
      simplePost(`${apiBaseUrl}/${ApiPath.WorkOrders}/${body.workOrderId}/commissions`, body),
  });
}

export function useWocActivateAllWorkPhases() {
  return useMutation({
    mutationKey: [QueryKey.WocActivateAllWorkPhases],
    mutationFn: (body: ActivateAllWorkPhasesBody) =>
      simplePost(`${apiBaseUrl}/${ApiPath.WorkOrders}/${body.woId}/commissions/${body.wocId}/activate-all-work-phases`),
  });
}

export function useWocUpdatePhase() {
  return useMutation({
    mutationKey: [QueryKey.WocUpdatePhase],
    mutationFn: (body: UpdatePhaseBody) =>
      simplePost(`${apiBaseUrl}/${ApiPath.WorkOrders}/commission-work-phase`, body),
  });
}

export function useStartWorkOrder() {
  return useMutation<AxiosResponse, AxiosError<{ message: string }>, string>({
    mutationKey: [QueryKey.StartWorkOrder],
    mutationFn: (id) => axios.put(`${apiBaseUrl}/${ApiPath.WorkOrders}/${id}/start`),
  });
}

export function useGetWorkOrderByPhaseKey(key: string) {
  return useSimpleQuery<WorkOrder[]>(
    [QueryKey.GetWorkOrderByPhaseKey, key],
    `${apiBaseUrl}/${ApiPath.WorkOrders}/phase/${key}`,
  );
}

export function useGetWorkOrderByIdAndPhase(workOrderId: string, phaseKey: string) {
  return useSimpleQuery<WorkOrder>(
    [QueryKey.GetWorkOrderByIdAndPhase, workOrderId, phaseKey],
    `${apiBaseUrl}/${ApiPath.WorkOrders}/phase/${phaseKey}/${workOrderId}`,
  );
}

export function useWorkOrderCommissionPhase() {
  return useMutation({
    mutationKey: [QueryKey.WorkOrderCommissionPhase],
    mutationFn: (body: WorkOrderCommissionPhaseBody) =>
      simplePost(`${apiBaseUrl}/${ApiPath.WorkOrders}/commission-phase/${body.phaseId}/${body.action}`),
  });
}

export function useGetCompletedWorkOrders() {
  return useSimpleQuery([QueryKey.GetCompletedWorkOrders], `${apiBaseUrl}/${ApiPath.WorkOrders}/completed`);
}

export function useGetWorkOrderPhaseCommission(workPhaseKey: string, workOrderId: string, workOrderCommissionId: string) {
  return useSimpleQuery<WorkOrderPhaseCommission>(
    [QueryKey.GetWorkOrderPhaseCommission],
    `${apiBaseUrl}/${ApiPath.WorkOrders}/phase/${workPhaseKey}/${workOrderId}/${workOrderCommissionId}`,
  );
}
