import React from 'react';
import { ConfigProvider, theme } from 'antd';
import { RootRouter } from './router/RootRouter';
import './App.css';
import { useInitApp } from '@hooks/useInitApp';

function App() {
  const { contextHolder, isLoading } = useInitApp();

  if (isLoading) {
    return null;
  }

  return (
    <ConfigProvider
      theme={{
        algorithm: [theme.defaultAlgorithm],
        // "components": {
        //   "Table": {
        //     "headerBg": "rgba(19,194,194,0.3)"
        //   }
        // }
      }}
    >
      <RootRouter />
      {contextHolder}
    </ConfigProvider>
  );
}

export default App;
