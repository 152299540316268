import React, { useEffect } from 'react';
import { Button, Card, Col, Form, Input, Modal, Row, Space, Spin, Table, Typography } from 'antd';
import { useCreateTicketType, useDeleteTicketType, useGetTicketTypes, useUpdateTicketType } from '@api/ticket-types';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  closeModal,
  openModal,
  registryModalsState,
  selectedTicketTypeState,
  setSelectedTicketType,
} from '@store/registry';
import { TicketType } from '@api/types/ticket-types';
import { ColumnProps } from 'antd/es/table';

export function CreateTicketTypeModal() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const { createTicketType: open } = useAppSelector(registryModalsState);

  const { mutate, isSuccess, reset } = useCreateTicketType();
  const { refetch } = useGetTicketTypes();

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      reset();
      refetch();
      close();
    }
  }, [isSuccess]);

  const close = () => dispatch(closeModal('createTicketType'));

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => {
    form.resetFields();
    close();
  };

  const onFinish = (values: Pick<TicketType, 'name'>) => {
    mutate(values);
  };

  return (
    <Modal
      title="Nuova Tipologia"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      styles={{ body: { paddingTop: 8, paddingBottom: 32 } }}
    >
      <Form form={form} name="create-ticket-type" layout="vertical" onFinish={onFinish}>
        <Form.Item name="name" label="Nome" rules={[{ required: true, message: 'Inserisci un nome valido' }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export function UpdateTicketTypeModal() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const type = useAppSelector(selectedTicketTypeState);

  const { updateTicketType: open } = useAppSelector(registryModalsState);

  const { mutate, isSuccess, reset } = useUpdateTicketType();
  const { refetch } = useGetTicketTypes();

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      dispatch(setSelectedTicketType(null));
      reset();
      refetch();
      close();
    }
  }, [isSuccess]);

  const close = () => dispatch(closeModal('updateTicketType'));

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => {
    form.resetFields();
    dispatch(setSelectedTicketType(null));
    close();
  };

  const onFinish = (values: Pick<TicketType, 'name'>) => {
    mutate({ ...values, id: type?.id ?? '' });
  };

  return (
    <Modal
      title="Nuova Tipologia"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      styles={{ body: { paddingTop: 8, paddingBottom: 32 } }}
    >
      <Form form={form} name="create-ticket-type" layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="name"
          label="Nome"
          initialValue={type?.name ?? ''}
          rules={[{ required: true, message: 'Inserisci un nome valido' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export function DeleteTicketTypeModal() {
  const dispatch = useAppDispatch();
  const { deleteTicketType: open } = useAppSelector(registryModalsState);
  const type = useAppSelector(selectedTicketTypeState);

  const { mutate, isSuccess, reset } = useDeleteTicketType();
  const { refetch } = useGetTicketTypes();

  useEffect(() => {
    if (isSuccess) {
      dispatch(setSelectedTicketType(null));
      reset();
      refetch();
      close();
    }
  }, [isSuccess]);

  const close = () => dispatch(closeModal('deleteTicketType'));

  const onOk = () => {
    mutate(type?.id ?? '');
  };

  const onCancel = () => {
    setSelectedTicketType(null);
    close();
  };

  return (
    <Modal
      title="Elimina Tipologia"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      styles={{ body: { paddingTop: 8, paddingBottom: 32 } }}
    >
      <Typography style={{ marginBottom: 8 }}>Sei sicuro di voler cancellare la seguente tipologia?</Typography>

      <Typography.Title level={5} style={{ textAlign: 'center' }}>{type?.name ?? ''}</Typography.Title>
    </Modal>
  );
}

export function TicketTypes() {
  const dispatch = useAppDispatch();

  const { data: ticketTypes, isLoading } = useGetTicketTypes();

  console.log('ticket-types', ticketTypes);

  const onNew = () => {
    dispatch(openModal('createTicketType'));
  };

  const onEdit = (type: TicketType) => {
    dispatch(setSelectedTicketType(type));
    dispatch(openModal('updateTicketType'));
  };

  const onDelete = (type: TicketType) => {
    dispatch(setSelectedTicketType(type));
    dispatch(openModal('deleteTicketType'));
  };


  const columns: ColumnProps<TicketType>[] = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record)}>Modifica</Button>
          <Button type="link" onClick={() => onDelete(record)}>Elimina</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card
          title="Tipologie Segnalazioni"
          extra={<Button type="primary" onClick={onNew}>Aggiungi Tipologia</Button>}
        >
          <Spin spinning={isLoading}>
            <Table
              columns={columns}
              dataSource={ticketTypes}
              pagination={{
                pageSizeOptions: ['10', '20', '30', '50'],
                responsive: true,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
                total: ticketTypes?.length ?? 0,
              }}
              rowKey="id"
              size="small"
            />
          </Spin>
        </Card>
      </Col>

      <CreateTicketTypeModal />
      <UpdateTicketTypeModal />
      <DeleteTicketTypeModal />
    </Row>
  );
}
