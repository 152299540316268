import { red } from '@ant-design/colors';
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';

interface Props {
  onClick?: () => void;
}

export function DeleteIconButton ({ onClick }: Props) {
  return (
    <Button type="text" style={{ color: red[3] }} onClick={onClick}><DeleteOutlined style={{ color: red[3] }} /></Button>
  )
}
