import React, { useEffect } from 'react';
import { Button, Card, Col, Row, Space, Table, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useGetOrder } from '@api/orders/useGetOrder';
import { FilePdfOutlined, MailOutlined, PlusOutlined } from '@ant-design/icons';
import { AddOrderMaterialModal } from './components/AddOrderMaterialModal';
import { useGetOrderMaterials } from '@api/orders/useGetOrderMaterials';
import { ColumnProps } from 'antd/es/table';
import { Order, OrderMaterial } from '@api/types';
import { useDownloadOrderPdf } from '@api/orders/useDownloadOrderPdf';
import { format } from 'date-fns';
import { useUpdateOrder } from '@api/orders/useUpdateOrder';
import { useNotification } from '@hooks/useNotification';
import { DeleteOrderMaterialModal } from '@routes/orders/components/DeleteOrderMaterialModal';
import { useAppDispatch } from '@hooks/redux';
import {
  openModal,
  setSelectedOrder,
  setSelectedOrderId,
  setSelectedOrderMaterial,
  setSelectedSupplierId,
} from '@store/orders';
import { EditOrderMaterialModal } from '@routes/orders/components/EditOrderMaterialModal';
import { DateFormat } from '@constants/constants';
import { ConfirmOrderModal } from '@routes/orders/components/ConfirmOrderModal';

export function EditOrder() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { openErrorMessage, contextHolder } = useNotification();

  const { data: order, isLoading: isLoadingOrder, refetch: refetchOrder } = useGetOrder(params?.id ?? '');
  const { data: materials, isLoading: isLoadingMaterials } = useGetOrderMaterials(params?.id ?? '');

  const { mutate: downloadOrderPdf, isSuccess: isSuccessDownloadPdf } = useDownloadOrderPdf();
  const {
    mutate: updateOrder,
    isSuccess: isSuccessUpdateOrder,
    reset: resetUpdateOrder,
    isError: isErrorUpdateOrder,
    error: errorUpdateOrder,
  } = useUpdateOrder();

  useEffect(() => {
    if (params.id) {
      dispatch(setSelectedOrderId(params.id));
    }
  }, []);

  useEffect(() => {
    dispatch(setSelectedSupplierId(order?.supplierId ?? ''));
  }, [order]);

  useEffect(() => {
    if (isSuccessDownloadPdf) {
      refetchOrder();
    }
  }, [isSuccessDownloadPdf]);

  useEffect(() => {
    if (isSuccessUpdateOrder) {
      refetchOrder();
    }
  }, [isSuccessUpdateOrder]);

  useEffect(() => {
    if (isErrorUpdateOrder) {
      resetUpdateOrder();

      if (errorUpdateOrder.response?.data.message === 'empty_order') {
        openErrorMessage('Impossibile inviare, l\'ordine non contiene materiali.');
      }
    }
  }, [isErrorUpdateOrder]);

  const add = () => {
    dispatch(openModal('addMaterial'));
  };

  const onSend = (order: Order) => {
    updateOrder({ orderId: order.id, order: { status: 'sent' } });
  };

  const onConfirm = (order: Order) => {
    dispatch(setSelectedOrder(order));
    dispatch(openModal('confirmOrder'));
  };

  const onEdit = (orderMaterial: OrderMaterial) => {
    dispatch(setSelectedOrderMaterial(orderMaterial));
    dispatch(openModal('editOrderMaterial'));
  };

  const onDelete = (orderMaterial: OrderMaterial) => {
    dispatch(setSelectedOrderMaterial(orderMaterial));
    dispatch(openModal('deleteOrderMaterial'));
  };

  const columns: ColumnProps<OrderMaterial>[] = [
    {
      title: 'Codice',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Descrizione',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Quantità',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Quantità Arrivata',
      dataIndex: 'arrivedQuantity',
      key: 'arrivedQuantity',
      render: (_, record) => order?.status === 'arrived' && record.arrivedQuantity === null ? record.quantity : record.arrivedQuantity,
    },
    {
      title: 'Prezzo',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="small">
          <Button type="text" onClick={() => onEdit(record)}>Modifica</Button>
          <Button type="text" danger onClick={() => onDelete(record)}>Rimuovi</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  const mailto = `mailto:${order?.supplier.email ?? ''}?subject=O${order?.year}-${order?.serial} ${format(new Date(), DateFormat)}`;

  if (isLoadingOrder || isLoadingMaterials) {
    return null;
  }

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
        <Card>
          <Col>
            <Typography.Text>Seriale</Typography.Text>
          </Col>
          <Col>
            <Typography.Text
              style={{
                fontSize: 18,
                fontWeight: 'bold',
              }}
            >{`OFABL${order?.year}-${order?.serial}`}</Typography.Text>
          </Col>
        </Card>
      </Col>

      <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
        <Card>
          <Col>
            <Typography.Text>Inviato</Typography.Text>
          </Col>
          <Col>
            <Typography.Text
              style={{
                fontSize: 18,
                fontWeight: 'bold',
              }}
            >{order?.sentAt ? format(order.sentAt, DateFormat) : 'Non Inviato'}</Typography.Text>
          </Col>
        </Card>
      </Col>

      <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
        <Card>
          <Col>
            <Typography.Text>Confermato</Typography.Text>
          </Col>
          <Col>
            <Typography.Text
              style={{
                fontSize: 18,
                fontWeight: 'bold',
              }}
            >{order?.confirmedAt ? format(order.confirmedAt, DateFormat) : 'Non Confermato'}</Typography.Text>
          </Col>
        </Card>
      </Col>

      <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
        <Card>
          <Col>
            <Typography.Text>Ricevuto</Typography.Text>
          </Col>
          <Col>
            <Typography.Text
              style={{
                fontSize: 18,
                fontWeight: 'bold',
              }}
            >{order?.arrivedAt ? format(order.arrivedAt, DateFormat) : 'Non Ricevuto'}</Typography.Text>
          </Col>
        </Card>
      </Col>

      <Col span={24}>
        <Card>
          <Col>
            <Typography.Text>Fornitore</Typography.Text>
          </Col>
          <Col>
            <Typography.Text style={{ fontSize: 18, fontWeight: 'bold' }}>{order?.supplier.name}</Typography.Text>
          </Col>
        </Card>
      </Col>

      <Col span={24}>
        <Card>
          <Row justify="end" style={{ marginBottom: 20 }}>
            <Space size="middle">
              {order?.status !== 'arrived' && (
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Aggiungi Prodotto
                </Button>
              )}
              <Button onClick={() => downloadOrderPdf(order)}><FilePdfOutlined /></Button>
              <Button onClick={() => window.location.href = mailto}><MailOutlined /></Button>
              {order?.status === 'draft' && materials && materials.length > 0 && (
                <Button type="primary" onClick={() => order && onSend(order)}>Invia</Button>
              )}

              {order?.status === 'sent' && order?.confirmed === false && (
                <Button type="primary" onClick={() => order && onConfirm(order)}>Conferma</Button>
              )}
            </Space>
          </Row>

          <Table
            columns={columns}
            dataSource={materials}
            pagination={false}
            rowKey="id"
            size="small"
          />
        </Card>
      </Col>
      <AddOrderMaterialModal />
      <EditOrderMaterialModal />
      <DeleteOrderMaterialModal />
      <ConfirmOrderModal />
      {contextHolder}
    </Row>
  );
}
