import { useMutation, useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { TicketType } from '@api/types/ticket-types';
import { simpleGet } from '@api/utils';

export function useGetTicketTypes() {
  return useQuery({
    queryKey: [QueryKey.GetTicketTypes],
    queryFn: () => simpleGet<TicketType[]>(`${apiBaseUrl}/${ApiPath.TicketTypes}`),
  })
}

export function useCreateTicketType() {
  return useMutation({
    mutationKey: [QueryKey.CreateTicketType],
    mutationFn: (body: Pick<TicketType, 'name'>) => axios.post<TicketType>(`${apiBaseUrl}/${ApiPath.TicketTypes}`, body),
  })
}

export function useUpdateTicketType() {
  return useMutation({
    mutationKey: [QueryKey.UpdateTicketType],
    mutationFn: (body: TicketType) => axios.put<TicketType>(`${apiBaseUrl}/${ApiPath.TicketTypes}/${body.id}`, body),
  })
}

export function useDeleteTicketType() {
  return useMutation({
    mutationKey: [QueryKey.DeleteTicketType],
    mutationFn: (id: string) => axios.delete(`${apiBaseUrl}/${ApiPath.TicketTypes}/${id}`),
  })
}
