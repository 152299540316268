import { Card, Col, Row, Table, Typography } from 'antd';
import { useGetCustomerTickets } from '@api/tickets';
import React from 'react';
import { ColumnProps } from 'antd/es/table';
import { Ticket } from '@api/types';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { DateFormat } from '@constants/constants';
import { getWorkOrderStatusLabel } from '@routes/work-orders/utils';

export function TicketsCustomer() {
  const navigate = useNavigate();
  const { data: tickets } = useGetCustomerTickets();

  console.log('tickets', tickets);

  const columns: ColumnProps<Ticket>[] = [
    {
      title: 'Seriale',
      key: 'serial',
      render: (_, record) => <Typography>{record.serial}</Typography>,
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) =>
        <Typography>{record.status === 'open' ? 'Aperta' : 'Chiusa'}</Typography>,
    },
    {
      title: 'Tipologia',
      key: 'typeName',
      dataIndex: 'typeName',
    },
    {
      title: 'Ordine di Lavoro',
      key: 'workOrderSerial',
      dataIndex: 'workOrderSerial',
      render: (_, record) => <Typography>{record.workOrderSerial}</Typography>,
    },
    {
      title: 'Commessa',
      key: 'workOrderCommissionSerial',
      dataIndex: 'workOrderCommissionSerial',
    },
    {
      title: 'Centro di Lavoro',
      key: 'workPhaseKey',
      dataIndex: 'workPhaseKey',
      render: value => getWorkOrderStatusLabel(value)
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => !!record.createdAt && format(record.createdAt, DateFormat),
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card title="Segnalazioni">
          <Table
            columns={columns}
            dataSource={tickets}
            onRow={(row) => {
              return {
                onClick: () => navigate(`/tickets/${row.id}`),
                style: { cursor: 'pointer' },
              };
            }}
            pagination={{
              pageSizeOptions: ['10', '20', '30', '50'],
              responsive: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
              total: tickets?.length ?? 0,
            }}
            rowKey="id"
          />
        </Card>
      </Col>
    </Row>
  );
}
