import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

export async function simpleGet<R>(url: string) {
  try {
    const response = await axios.get<R>(url);

    return response.data;
  } catch (err) {
    console.error(err);
  }
}

export async function simplePost<R, B = {}>(url: string, body?: B): Promise<R> {
  try {
    const response = await axios.post<R>(url, body);

    return response?.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function simplePut<R, B = {}>(url: string, body?: B): Promise<R> {
  try {
    const response = await axios.put<R>(url, body);

    return response?.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function simpleDelete<R>(url: string): Promise<R> {
  try {
    const response = await axios.delete<R>(url);

    return response?.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export function useSimpleQuery<R>(key: string[], url: string) {
  return useQuery({
    queryKey: key,
    queryFn: () => simpleGet<R>(url),
  });
}
