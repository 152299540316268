import { Button, Card, Col, Row, Space, Table, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useCreateWorkOrder, useGetWorkOrders } from '@api/work-orders';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { WorkOrder } from '@api/types';


export function WorkOrders() {
  const navigate = useNavigate();
  const { data: workOrders } = useGetWorkOrders();

  const {
    mutate: createWorkOrder,
    isSuccess: isSuccessCreateWorkOrder,
    data: createWorkOrderData,
  } = useCreateWorkOrder();

  useEffect(() => {
    if (isSuccessCreateWorkOrder) {
      navigate(`/work-orders/${createWorkOrderData.id}`);
    }
  }, [isSuccessCreateWorkOrder, createWorkOrderData?.id]);

  const onNew = () => {
    createWorkOrder();
  };

  const onEdit = (id: string) => {
    navigate(`/work-orders/${id}`);
  };

  const columns: ColumnProps<WorkOrder>[] = [
    {
      title: 'Seriale',
      key: 'serial',
      render: (_, record) => <Typography>{record.year}-{record.serial}</Typography>,
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) =>
        <Typography>{record.completed ? 'Completato' : record.started ? 'Avviato' : 'Non Avviato'}</Typography>,
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card title="Ordini di Lavoro" extra={<Button type="primary" onClick={onNew}>Nuovo Ordine</Button>}>
          <Table
            columns={columns}
            dataSource={workOrders}
            onRow={(row) => {
              return {
                onClick: () => onEdit(row.id),
                style: { cursor: 'pointer' },
              };
            }}
            pagination={{
              pageSizeOptions: ['10', '20', '30', '50'],
              responsive: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
              total: workOrders?.length ?? 0,
            }}
            rowKey="id"
          />
        </Card>
      </Col>
    </Row>
  );
}
