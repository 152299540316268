import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import { simpleDelete, simplePost, simplePut } from '@api/utils';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { CreateCustomerPhoneNumberData, CustomerPhoneNumber } from '@api/types';

export function useCreateCustomerPhoneNumber() {
  return useMutation({
    mutationKey: [QueryKey.CreateCustomerPhoneNumber],
    mutationFn: (data: CreateCustomerPhoneNumberData) =>
      simplePost<CustomerPhoneNumber>(`${apiBaseUrl}/${ApiPath.Customers}/${data.customerId}/phone-number`, data),
  });
}

export function useUpdateCustomerPhoneNumber() {
  return useMutation({
    mutationKey: [QueryKey.UpdateCustomerPhoneNumber],
    mutationFn: (data: CustomerPhoneNumber) =>
      simplePut(`${apiBaseUrl}/${ApiPath.Customers}/${data.customerId}/phone-number/${data.id}`, data),
  });
}

export function useDeleteCustomerPhoneNumber() {
  return useMutation({
    mutationKey: [QueryKey.DeleteCustomerPhoneNumber],
    mutationFn: (data: CustomerPhoneNumber) => simpleDelete(`${apiBaseUrl}/${ApiPath.Customers}/${data.customerId}/phone-number/${data.id}`),
  });
}

export * from './useGetCustomers';
