import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Dashboard } from '@routes/dashboard';
import {
  Customer,
  Customers,
  EditProduct,
  Material,
  Materials,
  PriceListRoute,
  Products,
  Referrers,
  Sources,
  Supplier,
  Suppliers,
  TicketTypes,
} from '@routes/registry';
import { EditQuotation, MetalSheetQuotesRoute, Quotations } from '@routes/quotations';
import { CommissionRoute, Commissions } from '@routes/commissions';
import { TicketsCustomer } from '@routes/tickets/TicketsCustomer';
import { TicketCustomer } from '@routes/tickets/TicketCustomer';
import { TicketsManager } from '@routes/tickets/TicketsManager';
import { CheckOrder, EditOrder, IncomingOrdersRoute, Orders } from '@routes/orders';
import { Warehouse } from '@routes/warehouse';
import { EditWorkOrder, WorkOrders, WorkPhaseOrders } from '@routes/work-orders';
import { WorkPhaseOrder } from '@routes/work-orders/WorkPhaseOrder';
import { WorkOrderPhaseCommissionRoute } from '@routes/work-orders/WorkPhaseCommission';
import { WorkersRoute } from '@routes/workers/WorkersRoute';
import { Notifications } from '@routes/notifications';
import { ProfileRoute } from '@routes/settings/ProfileRoute';
import { UsersRoute } from '@routes/settings/UsersRoute';
import { EditUserRoute } from '@routes/settings';
import { WorkstationRoute } from '@routes/settings/WorkstationRoute';
import { Logout } from '@routes/auth';
import { MainLayout } from '../layouts/MainLayout';
import { useAppSelector } from '@hooks/redux';
import { userRolesState } from '@store/user';
import { PrivateRoute } from './PrivateRoute';
import { TicketManager } from '@routes/tickets/TicketManager';
import { Devices } from '@routes/iot/Devices';
import { Energy } from '@routes/iot/Energy';
import { Device } from '@routes/iot/Device';
import { Assets } from '@routes/assets/Assets';
import { Asset } from '@routes/assets/Asset';
import { AssetMaintenance } from '@routes/assets/AssetMaintenance';
import { AdministrationCommissions } from '@routes/administration/AdministrationCommissions';

export function PrivateRoutes() {
  const roles = useAppSelector(userRolesState);

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="registry">
          <Route path="customers" element={<Customers />} />
          <Route path="customers/:id" element={<Customer />} />
          <Route path="suppliers" element={<Suppliers />} />
          <Route path="suppliers/:id" element={<Supplier />} />
          <Route path="referrers" element={<Referrers />} />
          <Route path="sources" element={<Sources />} />
          <Route path="ticket-types" element={<TicketTypes />} />
          <Route path="materials" element={<Materials />} />
          <Route path="materials/:id" element={<Material />} />
          <Route path="products" element={<Products />} />
          <Route path="products/:id" element={<EditProduct />} />
          <Route path="price-list" element={<PriceListRoute />} />
        </Route>

        <Route path="quotes">
          <Route index element={<Quotations />} />
          <Route path=":id" element={<EditQuotation />} />
        </Route>

        <Route path="metal-sheet-quotes" element={<MetalSheetQuotesRoute />} />

        <Route path="commissions">
          <Route index element={<Commissions />} />
          <Route path=":id" element={<CommissionRoute />} />
        </Route>

        <Route path="tickets">
          <Route index element={<TicketsCustomer />} />
          <Route path=":id" element={<TicketCustomer />} />
        </Route>

        <Route path="tickets-manager">
          <Route index element={<TicketsManager />} />
          <Route path=":id" element={<TicketManager />} />
        </Route>

        <Route path="tickets-manager" element={<TicketsManager />} />
        <Route path="orders">
          <Route index element={<Orders />} />
          <Route path=":id" element={<EditOrder />} />
        </Route>

        <Route path="incoming-orders">
          <Route index element={<IncomingOrdersRoute />} />
          <Route path=":id" element={<CheckOrder />} />
        </Route>

        <Route path="warehouse" element={<Warehouse />} />

        <Route path="work-orders">
          <Route index element={<WorkOrders />} />
          <Route path=":id" element={<EditWorkOrder />} />
        </Route>

        {['laser-cut', 'folding', 'welding', 'painting', 'assembly'].map((phase) => (
          <Route key={phase} path={phase}>
            <Route index element={<WorkPhaseOrders workPhaseKey={phase} />} />
            <Route path=":id" element={<WorkPhaseOrder workPhaseKey={phase} />} />
            <Route
              path=":workOrderId/:workOrderCommissionId"
              element={<WorkOrderPhaseCommissionRoute workPhaseKey={phase} />}
            />
          </Route>
        ))}

        <Route path="workers" element={<WorkersRoute />} />

        <Route path="notifications" element={<Notifications />} />

        <Route path="settings">
          <Route path="profile" element={<ProfileRoute />} />
          <Route
            path="users"
            element={<PrivateRoute requiredRoles={['admin']} userRoles={roles}><UsersRoute /></PrivateRoute>}
          />
          <Route path="users/:id" element={<EditUserRoute />} />
          <Route path="workstation" element={<WorkstationRoute />} />
          <Route
            path="iot-devices"
            element={<PrivateRoute requiredRoles={['energy-manager']} userRoles={roles}><Devices /></PrivateRoute>}
          />
          <Route
            path="iot-devices/:id"
            element={<PrivateRoute requiredRoles={['energy-manager']} userRoles={roles}><Device /></PrivateRoute>}
          />
        </Route>

        <Route
          path="energy"
          element={<PrivateRoute requiredRoles={['energy-manager']} userRoles={roles}><Energy /></PrivateRoute>}
        />

        <Route path="assets">
          <Route
            index
            element={<PrivateRoute
              requiredRoles={['admin', 'engineering']}
              userRoles={roles}
            ><Assets /></PrivateRoute>}
          />

          <Route
            path=":id"
            element={<PrivateRoute
              requiredRoles={['admin', 'engineering']}
              userRoles={roles}
            ><Asset /></PrivateRoute>}
          />
          <Route
            path=":assetId/maintenances/:maintenanceId"
            element={<PrivateRoute
              requiredRoles={['admin', 'engineering']}
              userRoles={roles}
            ><AssetMaintenance /></PrivateRoute>}
          />
        </Route>

        <Route path="administration">
          <Route
            path="commissions"
            element={<PrivateRoute
              requiredRoles={['admin', 'administration']}
              userRoles={roles}
            ><AdministrationCommissions /></PrivateRoute>}
          />
        </Route>

        <Route path="logout" element={<Logout />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
}
