import { Card, Col, Row } from 'antd';
import { EnergyChart } from '@components/EnergyChart';

export function Device() {
  return (
    <Row>
      <Col xs={24}>
        <Card title="Dispositivo">
          <EnergyChart />
        </Card>
      </Col>
    </Row>
  )
}
