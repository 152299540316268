import { Button, Card, Col, Row } from 'antd';
import React from 'react';
import { CreateSupplierModal } from './CreateSupplierModal';
import { useAppDispatch } from '@hooks/redux';
import { openModal } from '@store/suppliers';
import { SuppliersTable } from './SuppliersTable';

export function Suppliers() {
  const dispatch = useAppDispatch();

  const onNew = () => dispatch(openModal('createSupplier'));

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          title="Fornitori"
          extra={<Button type="primary" onClick={onNew}>Aggiungi Fornitore</Button>}
        >
          <SuppliersTable />
        </Card>
      </Col>
      <CreateSupplierModal />
    </Row>
  );
}
