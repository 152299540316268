import { useDeleteCustomer } from '@api/customers/useDeleteCustomer';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  closeModal,
  customerModalsState,
  selectedCustomerPhoneNumberState,
  selectedCustomerState,
  setSelectedCustomer,
  setSelectedCustomerPhoneNumber,
} from '@store/customers';
import { ConfirmModal } from '@components/modals/ConfirmModal';
import { useGetCustomer } from '@api/customers/useGetCustomer';
import { useDeleteCustomerPhoneNumber } from '@api/customers';


export function DeletePhoneNumberModal() {
  const dispatch = useAppDispatch();
  const { deleteCustomerPhoneNumber: open } = useAppSelector(customerModalsState);
  const customer = useAppSelector(selectedCustomerState);
  const phone = useAppSelector(selectedCustomerPhoneNumberState);

  const { mutate, isSuccess, reset } = useDeleteCustomerPhoneNumber();
  const { refetch } = useGetCustomer(customer?.id ?? '');

  const close = () => {
    dispatch(setSelectedCustomer(null));
    dispatch(setSelectedCustomerPhoneNumber(null));
    dispatch(closeModal('deleteCustomerPhoneNumber'));
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      refetch();
      close();
    }
  }, [isSuccess]);

  const onOk = () => {
    if (phone) {
      mutate({ ...phone, customerId: customer?.id ?? '' });
    }
  };

  return (
    <ConfirmModal
      title="Elimina Recapito Telefonico"
      message="Sei sicuro di voler eliminare il seguente numero di telefono?"
      subject={`${phone?.number ?? ''}`}
      okText="Elimina"
      okType="danger"
      open={open}
      onOk={onOk}
      onCancel={close}
    />
  );
}
