export * from './constants';
export * from './WorkstationType';

export enum ApiPath {
  Administration = 'administration',
  Assets = 'assets',
  Auth = 'auth',
  Commissions = 'commissions',
  Customers = 'customers',
  IncomingOrders = 'incoming-orders',
  Iot = 'iot',
  Notifications = 'notifications',
  Orders = 'orders',
  PaymentModes = 'payment-modes',
  Products = 'products',
  QuotationReferrers = 'quotation-referrers',
  QuotationSources = 'quotation-sources',
  Quotations = 'quotations',
  Referrers = 'referrers',
  SupplierMaterials = 'supplier-materials',
  Suppliers = 'suppliers',
  Tickets = 'tickets',
  TicketsCustomer = 'tickets-customer',
  TicketsManager = 'tickets-manager',
  TicketTypes = 'ticket-types',
  Users = 'users',
  Warehouses = 'warehouses',
  WorkOrders = 'work-orders',
  WorkPhases = 'work-phases',
  Workers = 'workers',
}
