import { Button, Card, Col, Modal, Row, Table, Typography } from 'antd';
import { EditOutlined, FileTextOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetAssetMaintenance, useGetAssetMaintenanceActivities } from '@api/assets';
import { openModal } from '@store/assets';
import { useAppDispatch } from '@hooks/redux';
import { CreateMaintenanceActivityModal } from '@routes/assets/components/CreateMaintenanceActivityModal';
import { ColumnProps } from 'antd/es/table';
import { AssetMaintenanceActivity } from '@api/types/assets';
import { format } from 'date-fns';
import { DateFormat } from '@constants/constants';

enum Tab {
  Activities = 'activities',
  Info = 'info',
}

const tabList = [
  {
    key: 'activities',
    label: 'Interventi',
  },
  {
    key: 'info',
    label: 'Procedure',
  },
];

export function AssetMaintenance() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [note, setNote] = useState('');
  const [isOpenNoteModal, setIsOpenNoteModal] = useState(false);
  const [activeTab, setActiveTab] = useState<Tab>(Tab.Activities);
  const { data: maintenance } = useGetAssetMaintenance(params?.assetId ?? '', params?.maintenanceId ?? '');
  const { data: activities } = useGetAssetMaintenanceActivities(params?.assetId ?? '', params?.maintenanceId ?? '');

  console.log(activities);

  const onCreateActivityClick = () => {
    dispatch(openModal('createActivity'));
  };

  const onEditName = () => {
  };

  const activitiesColumns: ColumnProps<AssetMaintenanceActivity>[] = [
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: value => format(value, DateFormat),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: value => {
        switch (value) {
          case 'scheduled':
            return 'Programmato';
          case 'unscheduled':
            return 'Non Programmato';
          case 'failure':
            return 'Guasto';
          default:
            return '';
        }
      },
    },
    {
      title: 'Risultato',
      dataIndex: 'result',
      key: 'result',
      render: value => {
        switch (value) {
          case 'ok':
            return 'Esito Positivo';
          case 'repair':
            return 'Riparato';
          default:
            return '';
        }
      },
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      render: (_, record) => !!record.note ? <FileTextOutlined
        onClick={(e) => {
          e.stopPropagation();
          setNote(record.note);
          setIsOpenNoteModal(true);
        }} style={{ fontSize: 24, cursor: 'pointer' }}
      /> : '',
    },
  ];

  return (
    <Row>
      <Col span={24} style={{ marginBottom: 8 }}>
        <Card onClick={onEditName}>
          <Row justify="space-between">
            <Typography.Text>Nome</Typography.Text>

            <EditOutlined style={{ cursor: 'pointer' }} />
          </Row>
          <Col>
            <Typography.Text style={{ fontSize: 18, fontWeight: 'bold' }}>{maintenance?.name ?? ''}</Typography.Text>
          </Col>
        </Card>

        <Card style={{ marginBottom: 8 }}>
          <Row justify="space-between">
            <Typography.Text>Descrizione</Typography.Text>
          </Row>
          <Col style={{ marginTop: 16 }}>
            <Typography.Text style={{ fontSize: 14 }}>{maintenance?.description ?? ''}</Typography.Text>
          </Col>
        </Card>

        <Card tabList={tabList} activeTabKey={activeTab} onTabChange={(key) => setActiveTab(key as Tab)}>
          {activeTab === Tab.Activities && (
            <>
              <Row justify="end" style={{ marginBottom: 8 }}>
                <Button onClick={onCreateActivityClick}>Nuovo Intervento</Button>
              </Row>

              <Table
                columns={activitiesColumns}
                dataSource={activities}
                pagination={{
                  pageSizeOptions: ['10', '20', '30', '50'],
                  responsive: true,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
                  total: activities?.length ?? 0,
                }}
                rowKey="id"
              />

              <Modal
                title="Nota" open={isOpenNoteModal} onCancel={() => {
                setNote('');
                setIsOpenNoteModal(false);
              }} footer={null}
              >
                <Typography>{note}</Typography>
              </Modal>

              <CreateMaintenanceActivityModal />
            </>
          )}

          {activeTab === Tab.Info && (
            <>Info</>
          )}
        </Card>
      </Col>
    </Row>
  );
}
