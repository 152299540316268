import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { Order } from '@api/types';

interface ConfirmOrderPayload {
  orderId: string;
  confirmedAt: string;
}

export function useConfirmOrder() {
  return useMutation<AxiosResponse, AxiosError<{ message: string }>, ConfirmOrderPayload>({
    mutationKey: [QueryKey.UpdateOrder],
    mutationFn: ({ orderId, confirmedAt }: ConfirmOrderPayload) => {
      return axios.post<Order>(`${apiBaseUrl}/${ApiPath.Orders}/${orderId}/confirm`, { confirmedAt });
    },
  });
}
