import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { TicketType } from '@api/types/ticket-types';

interface RegistryModals {
  createTicketType: boolean;
  updateTicketType: boolean;
  deleteTicketType: boolean;
}

interface RegistryState {
  modals: RegistryModals;
  selectedTicketType: TicketType | null;
}

const initialState: RegistryState = {
  modals: {
    createTicketType: false,
    updateTicketType: false,
    deleteTicketType: false,
  },
  selectedTicketType: null,
};


export const registrySlice = createSlice({
  name: 'registry',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = true;
    },

    closeModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = false;
    },

    setSelectedTicketType: (state, action: PayloadAction<TicketType | null>) => {
      state.selectedTicketType = action.payload;
    },
  },
});

export const { openModal, closeModal, setSelectedTicketType } = registrySlice.actions;

export const registryModalsState = (state: RootState) => state.registry.modals;

export const selectedTicketTypeState = (state: RootState) => state.registry.selectedTicketType;

export default registrySlice.reducer;
