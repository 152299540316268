import { ConfirmModal } from '@components/modals/ConfirmModal';
import { DatePicker, Form } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { closeModal, isConfirmOrderModalOpenState, selectedOrderState, setSelectedOrder } from '@store/orders';
import { useConfirmOrder } from '@api/orders/useConfirmOrder';
import { useEffect, useState } from 'react';
import { useGetOrder } from '@api/orders/useGetOrder';
import { DateFormatDayjs } from '@constants/constants';
import { useForm } from 'antd/es/form/Form';

export function ConfirmOrderModal() {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const open = useAppSelector(isConfirmOrderModalOpenState);
  const selectedOrder = useAppSelector(selectedOrderState);
  const [confirmedAt, setConfirmedAt] = useState<string>('');

  const { refetch: refetchOrder } = useGetOrder(selectedOrder?.id ?? '');
  const { mutate: confirmOrder, isSuccess: isSuccessConfirmOrder, reset: resetConfirmOrder } = useConfirmOrder();

  const close = () => {
    dispatch(setSelectedOrder(null));
    dispatch(closeModal('confirmOrder'));
  };

  useEffect(() => {
    if (isSuccessConfirmOrder) {
      resetConfirmOrder();
      refetchOrder();
      close();
    }
  }, [isSuccessConfirmOrder]);

  return (
    <ConfirmModal
      title="Conferma Ordine"
      message="Per confermare l'ordine selezionare una data di consegna"
      open={open}
      onOk={() => form.submit()}
      onCancel={close}
      destroyOnClose
      styles={{ body: { minHeight: 200 } }}
    >
      <Form
        form={form} onFinish={(v) => {
        confirmOrder({
          orderId: selectedOrder?.id ?? '',
          confirmedAt: v.confirmedAt.format(),
        });
      }}
      >
        <Form.Item id="confirmedAt" name="confirmedAt" rules={[{ required: true, message: 'Data non valida' }]}>
          <DatePicker
            autoFocus
            format={DateFormatDayjs}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </ConfirmModal>
  );
}
