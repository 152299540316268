import { useMutation } from '@tanstack/react-query';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { simpleDelete, simplePost, simplePut, useSimpleQuery } from '@api/utils';
import {
  CreateTicketBody,
  CreateTicketMessageBody,
  DeleteTicketMessagePayload,
  Ticket,
  UpdateTicketBody,
} from '@api/types';
import { QueryKey } from '@constants/queries';

export enum TicketStatus {
  Open = 'open',
  Closed = 'closed',
}

export function useGetCustomerTickets() {
  return useSimpleQuery<Ticket[]>(
    [QueryKey.GetCustomerTickets],
    `${apiBaseUrl}/${ApiPath.TicketsCustomer}`,
  );
}

export function useGetManagerTickets() {
  return useSimpleQuery<Ticket[]>(
    [QueryKey.GetManagerTickets],
    `${apiBaseUrl}/${ApiPath.TicketsManager}`,
  )
}

export function useGetCustomerTicket(id: string) {
  return useSimpleQuery<Ticket>(
    [QueryKey.GetCustomerTicket, id],
    `${apiBaseUrl}/${ApiPath.TicketsCustomer}/${id}`,
  );
}

export function useGetManagerTicket(id: string) {
  return useSimpleQuery<Ticket>(
    [QueryKey.GetManagerTicket, id],
    `${apiBaseUrl}/${ApiPath.TicketsManager}/${id}`,
  );
}

export function useCreateCustomerTicket() {
  return useMutation({
    mutationKey: [QueryKey.CreateCustomerTicket],
    mutationFn: (body: CreateTicketBody) =>
      simplePost<Ticket>(`${apiBaseUrl}/${ApiPath.TicketsCustomer}`, body),
  });
}

export function useUpdateCustomerTicket() {
  return useMutation({
    mutationKey: [QueryKey.UpdateCustomerTicket],
    mutationFn: (payload: { id: string, body: UpdateTicketBody }) =>
      simplePut<Ticket, UpdateTicketBody>(`${apiBaseUrl}/${ApiPath.TicketsCustomer}/${payload.id}`, payload.body),
  });
}

export function useUpdateManagerTicket() {
  return useMutation({
    mutationKey: [QueryKey.UpdateManagerTicket],
    mutationFn: (payload: { id: string, body: UpdateTicketBody }) =>
      simplePut<Ticket, UpdateTicketBody>(`${apiBaseUrl}/${ApiPath.TicketsManager}/${payload.id}`, payload.body),
  });
}

export function useDeleteCustomerTicket() {
  return useMutation({
    mutationKey: [QueryKey.DeleteCustomerTicket],
    mutationFn: (id: string) =>
      simpleDelete<Ticket>(`${apiBaseUrl}/${ApiPath.TicketsCustomer}/${id}`),
  });
}

export function useCreateTicketMessage() {
  return useMutation({
    mutationKey: [QueryKey.CreateTicketMessage],
    mutationFn: (body: CreateTicketMessageBody) =>
      simplePost(`${apiBaseUrl}/${ApiPath.Tickets}/${body.ticketId}/messages`, body),
  });
}

export function useUpdateTicket() {
}

export function useDeleteCustomerTicketMessage() {
  return useMutation({
    mutationKey: [QueryKey.DeleteCustomerTicketMessage],
    mutationFn: ({ ticketId, messageId }: DeleteTicketMessagePayload) =>
      simpleDelete(`${apiBaseUrl}/${ApiPath.TicketsCustomer}/${ticketId}/messages/${messageId}`),
  })
}
