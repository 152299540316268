import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { Asset } from '@api/types/assets';

interface AssetsModals {
  createAsset: boolean;
  updateAsset: boolean;
  createAttribute: boolean;
  createMaintenance: boolean;
  createActivity: boolean;
}

interface AssetsState {
  modals: AssetsModals;
  selectedAssetId: string;
  selectedAsset: Asset | null;
}

const initialState: AssetsState = {
  modals: {
    createAsset: false,
    updateAsset: false,
    createAttribute: false,
    createMaintenance: false,
    createActivity: false,
  },
  selectedAssetId: '',
  selectedAsset: null,
};


export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = true;
    },

    closeModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = false;
    },

    setSelectedAssetId: (state, action: PayloadAction<string>) => {
      state.selectedAssetId = action.payload;
    },

    setSelectedAsset: (state, action: PayloadAction<Asset>) => {
      state.selectedAsset = action.payload;
    }
  },
});

export const { openModal, closeModal, setSelectedAssetId, setSelectedAsset } = assetsSlice.actions;

export const assetsModalsState = (state: RootState) => state.assets.modals;

export const isCreateAssetModalOpenState = (state: RootState) => state.assets.modals.createAsset;
export const isUpdateAssetModalOpenState = (state: RootState) => state.assets.modals.updateAsset;
export const isCreateAttributeModalOpenState = (state: RootState) => state.assets.modals.createAttribute;

export const selectedAssetIdState = (state: RootState) => state.assets.selectedAssetId;
export const selectedAssetState = (state: RootState) => state.assets.selectedAsset;

export default assetsSlice.reducer;
