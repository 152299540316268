import axios, { AxiosError } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import { ApiPath } from '@constants/index';
import { CreateOrderResponse } from '@api/types';

async function createOrder(payload: { supplierId: string }) {
  const response = await axios.post<CreateOrderResponse>(`${apiBaseUrl}/${ApiPath.Orders}`, payload);

  return response.data;
}

export function useCreateOrder() {
  return useMutation<CreateOrderResponse, AxiosError<{ message: string }>, { supplierId: string }>({
    mutationKey: [QueryKey.CreateOrder],
    mutationFn: createOrder,
  });
}
