import { Form, InputNumber, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  closeModal,
  commissionsModalsState,
  selectedCommissionState,
  selectedMaterialState,
  setSelectedCommission,
  setSelectedMaterial,
} from '@store/commissions';
import {
  UpdateCommissionMaterialData,
  useUpdateCommissionMaterial,
} from '@api/commissions/useUpdateCommissionMaterial';
import { useGetCommissionMaterials } from '@api/commissions/useGetCommissionMaterials';
import { useEffect } from 'react';

export function UpdateMaterialModal() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { updateMaterial: open } = useAppSelector(commissionsModalsState);
  const material = useAppSelector(selectedMaterialState);
  const commission = useAppSelector(selectedCommissionState);

  const { mutate, isSuccess, reset } = useUpdateCommissionMaterial();
  const { refetch } = useGetCommissionMaterials(commission?.id ?? '');

  useEffect(() => {
    form.setFieldsValue(material);
  }, [material]);

  useEffect(() => {
    if (isSuccess) {
      reset();
      refetch();
      close();
    }
  }, [isSuccess]);

  const close = () => {
    form.resetFields();
    dispatch(setSelectedCommission(null));
    dispatch(setSelectedMaterial(null));
    dispatch(closeModal('updateMaterial'));
  };

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => {
    close();
  };

  const onFinish = (values: Pick<UpdateCommissionMaterialData, 'reserved'>) => {
    if (commission && material) {
      mutate({
        commissionId: commission.id,
        commissionMaterialId: material.id,
        ...values,
      });
    }
  };

  return (
    <Modal title="Modifica Prenotazione" open={open} onOk={onOk} onCancel={onCancel}>
      <Form form={form} onFinish={onFinish} name="update-commission-material" layout="vertical">
        <Form.Item name="reserved" label="Quantità Prenotata" initialValue={material?.reserved ?? 0}>
          <InputNumber min={0} style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
