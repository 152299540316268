import React from 'react';
import { Card, Col, Row, Typography } from 'antd';

interface Props {
  title: string;
  text: string;
  onClick?: () => void;
  icon?: React.ReactElement;
}

const textStyle = {
  fontSize: 18,
  fontWeight: 'bold',
};

export function TicketInfo({ title, text, onClick, icon }: Props) {
  return (
    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
      <Card onClick={onClick} style={{ cursor: icon ? 'pointer' : 'default' }}>
        <Row justify="space-between">
          <Typography.Text>{title}</Typography.Text>

          {icon && icon}
        </Row>
        <Col>
          <Typography.Text style={textStyle}>{text}</Typography.Text>
        </Col>
      </Card>
    </Col>
  );
}
