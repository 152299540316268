import { Button, Card, Col, Row, Table } from 'antd';
import { useAppDispatch } from '@hooks/redux';
import { CreateAssetModal } from './components/CreateAssetModal';
import { openModal } from '@store/assets';
import { useGetAssets } from '@api/assets';
import React from 'react';
import { ColumnProps } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { Asset } from '@api/types/assets';

export function Assets() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data: assets } = useGetAssets();

  console.log('assets', assets);

  const onEdit = (id: string) => {
    navigate(`/assets/${id}`);
  };

  const columns: ColumnProps<Asset>[] = [
    {
      title: 'Seriale',
      dataIndex: 'serial',
      key: 'serial',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card
          title="Attrezzature"
          extra={<Button onClick={() => dispatch(openModal('createAsset'))}>Nuova Attrezzatura</Button>}
        >
          <Table
            columns={columns}
            dataSource={assets}
            onRow={(row) => {
              return {
                onClick: () => onEdit(row.id),
                style: { cursor: 'pointer' },
              };
            }}
            pagination={{
              pageSizeOptions: ['10', '20', '30', '50'],
              responsive: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
              total: assets?.length ?? 0,
            }}
            rowKey="id"
          />
        </Card>
      </Col>

      <CreateAssetModal />
    </Row>
  );
}
