import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';

export function useGetNotifications() {
  return useQuery({
    queryKey: [QueryKey.GetNotifications],
    queryFn: async () => {
      try {
        const result = await axios.get(`${apiBaseUrl}/${ApiPath.Notifications}`);

        return result.data;
      } catch (err) {
        console.error(err);
      }
    },
  });
}
