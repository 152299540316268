import { Button, Card, Col, Row, Table, Typography } from 'antd';
import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { useGetAsset, useGetAssetAttributes, useGetAssetMaintenances } from '@api/assets';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { AssetAttribute, AssetMaintenance } from '@api/types/assets';
import { CreateAttributeModal } from '@routes/assets/components/CreateAttributeModal';
import { useAppDispatch } from '@hooks/redux';
import { openModal, setSelectedAsset, setSelectedAssetId } from '@store/assets';
import { UpdateAssetModal } from '@routes/assets/components/UpdateAssetModal';
import { CreateMaintenanceModal } from '@routes/assets/components/CreateMaintenanceModal';

enum Tab {
  Info = 'info',
  Maintenances = 'maintenances',
}

function formatInterval(interval: string) {
  if (!interval) return ''; // gestione per valori null o undefined

  // Mappare le unità abbreviate di PostgreSQL ai termini leggibili
  const unitMap = {
    year: 'anno',
    years: 'anni',
    mon: 'mese',
    mons: 'mesi',
    day: 'giorni',
    hour: 'ore',
    min: 'minuti',
    sec: 'secondi',
  };

  // Suddividere l'intervallo in parti (esempio: "2 years 3 months")
  const parts = interval.split(' ');

  // Elaborare le parti in coppie (valore + unità)
  const formattedParts = [];
  for (let i = 0; i < parts.length; i += 2) {
    const value = parseInt(parts[i], 10); // valore numerico
    const unit = parts[i + 1]; // unità abbreviata
    // @ts-ignore
    const readableUnit = unitMap[unit] || unit; // tradurre unità se possibile

    if (!isNaN(value) && readableUnit) {
      formattedParts.push(`${value} ${readableUnit}`);
    }
  }

  // Unire tutte le parti in una stringa leggibile
  return formattedParts.join(' ');
}

export function Asset() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<Tab>(Tab.Maintenances);

  const { data: asset } = useGetAsset(params?.id ?? '');
  const { data: assetAttributes } = useGetAssetAttributes(params?.id ?? '');
  const { data: assetMaintenances } = useGetAssetMaintenances(params?.id ?? '');

  const onEditName = () => {
    if (asset) {
      dispatch(setSelectedAsset(asset));
      dispatch(openModal('updateAsset'));
    }
  };

  const tabList = [
    {
      key: 'maintenances',
      label: 'Manutenzioni',
    },
    {
      key: 'info',
      label: 'Informazioni',
    },
  ];

  const assetAttributesColumns: ColumnProps<AssetAttribute>[] = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Valore',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const assetMaintenancesColumns: ColumnProps<AssetMaintenance>[] = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Intervallo',
      dataIndex: 'interval',
      key: 'interval',
      render: value => formatInterval(value),
    },
    {
      title: 'Guasti',
      dataIndex: 'failureCount',
      key: 'failureCount',
    }
  ];

  const onCreateAttributeClick = () => {
    dispatch(setSelectedAssetId(asset?.id ?? ''));
    dispatch(openModal('createAttribute'));
  };

  const onCreateMaintenanceClick = () => {
    dispatch(setSelectedAssetId(asset?.id ?? ''));
    dispatch(openModal('createMaintenance'));
  };

  return (
    <Row>
      <Col span={2} style={{ marginBottom: 8 }}>
        <Card>
          <Row justify="space-between">
            <Typography.Text>Seriale</Typography.Text>
          </Row>
          <Col>
            <Typography.Text style={{ fontSize: 18, fontWeight: 'bold' }}>{asset?.serial ?? ''}</Typography.Text>
          </Col>
        </Card>
      </Col>

      <Col span={22} style={{ marginBottom: 8 }}>
        <Card onClick={onEditName}>
          <Row justify="space-between">
            <Typography.Text>Nome</Typography.Text>

            <EditOutlined style={{ cursor: 'pointer' }} />
          </Row>
          <Col>
            <Typography.Text style={{ fontSize: 18, fontWeight: 'bold' }}>{asset?.name ?? ''}</Typography.Text>
          </Col>
        </Card>
      </Col>

      <Col span={24}>
        <Card tabList={tabList} activeTabKey={activeTab} onTabChange={(key) => setActiveTab(key as Tab)}>
          {activeTab === Tab.Info && (
            <>
              <Row justify="end" style={{ marginBottom: 8 }}>
                <Button onClick={onCreateAttributeClick}>Nuova Informazione</Button>
              </Row>

              <Table
                columns={assetAttributesColumns}
                dataSource={assetAttributes}
                pagination={{
                  pageSizeOptions: ['10', '20', '30', '50'],
                  responsive: true,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
                  total: assetAttributes?.length ?? 0,
                }}
                rowKey="id"
              />

              <CreateAttributeModal />
            </>
          )}

          {activeTab === Tab.Maintenances && (
            <>
              <Row justify="end" style={{ marginBottom: 8 }}>
                <Button onClick={onCreateMaintenanceClick}>Nuova Manutenzione</Button>
              </Row>

              <Table
                columns={assetMaintenancesColumns}
                dataSource={assetMaintenances}
                onRow={(row) => {
                  return {
                    onClick: () => navigate(`/assets/${asset?.id ?? ''}/maintenances/${row.id}`),
                    style: { cursor: 'pointer' },
                  };
                }}
                pagination={{
                  pageSizeOptions: ['10', '20', '30', '50'],
                  responsive: true,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
                  total: assetMaintenances?.length ?? 0,
                }}
                rowKey="id"
              />

              <CreateMaintenanceModal />
            </>
          )}
        </Card>
      </Col>

      <UpdateAssetModal />
    </Row>
  );
}
