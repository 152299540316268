import { Avatar, Button, Card, Col, Row, Spin, Table, theme, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { CreateOrderModal } from './components/CreateOrderModal';
import { format } from 'date-fns';
import { Order } from '@api/types';
import { useGetOrders } from '@api/index';
import { useAppDispatch } from '@hooks/redux';
import { openModal } from '@store/orders';
import { DateFormat } from '@constants/constants';
import { getColorBasedOnBusinessDays } from '../../utils/getColorBasedOnBusinessDays';

export function Orders() {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: orders, isLoading: isLoadingOrders } = useGetOrders();

  const onNew = () => {
    dispatch(openModal('createOrder'));
  };

  const onEdit = (id: string) => {
    navigate(`/orders/${id}`);
  };

  const columns: ColumnProps<Order>[] = [
    {
      title: 'Seriale',
      dataIndex: 'serial',
      key: 'serial',
      render: (_, record) => <Typography>OFABL{record.year}-{record.serial}</Typography>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        let backgroundColor = 'transparent';

        if (record.arrivedAt !== null) {
          backgroundColor = token.colorSuccess;
        } else if (record.confirmedAt !== null) {
          backgroundColor = getColorBasedOnBusinessDays(new Date(record.confirmedAt), new Date(), 3, {
            red: token.red,
            orange: token.orange,
            green: token.colorFill,
          });
        }

        return (
          <Avatar
            size={14}
            style={{ backgroundColor }}
          />
        );
      },
    },
    {
      title: 'Fornitore',
      dataIndex: 'supplier',
      key: 'supplier',
      render: (supplier) => <Typography>{supplier?.name ?? ''}</Typography>,
    },
    {
      title: 'Materiali',
      dataIndex: 'materials',
      key: 'materials',
    },
    {
      title: 'Inviato',
      dataIndex: 'sentAt',
      key: 'sentAt',
      render: (_, record) =>
        <Typography>{record.sentAt ? format(record.sentAt, DateFormat) : 'Non Inviato'}</Typography>,
    },
    {
      title: 'Presunta Conferma',
      dataIndex: 'confirmedAt',
      key: 'confirmedAt',
      render: (_, record) =>
        <Typography>{record.confirmed ? format(record.confirmedAt, DateFormat) : 'Non Confermato'}</Typography>,
    },
    {
      title: 'Arrivato',
      dataIndex: 'arrivedAt',
      render: (_, record) =>
        <Typography>{record.arrivedAt ? format(record.arrivedAt, DateFormat) : 'Non Arrivato'}</Typography>,
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card title="Ordini a Fornitore" extra={<Button type="primary" onClick={onNew}>Nuovo Ordine</Button>}>
          <Spin spinning={isLoadingOrders}>
            <Table
              columns={columns}
              dataSource={orders}
              onRow={(row) => {
                return {
                  onClick: () => onEdit(row.id),
                  style: { cursor: 'pointer' },
                };
              }}
              pagination={{
                pageSizeOptions: ['10', '20', '30', '50'],
                responsive: true,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
                total: orders?.length ?? 0,
              }}
              rowKey="id"
            />
          </Spin>
        </Card>
      </Col>
      <CreateOrderModal />
    </Row>
  );
}
