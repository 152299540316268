import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { WorkstationType } from '@constants/WorkstationType';

export type WorkCenter = 'laser-cut' | 'folding' | 'welding' | 'painting' | 'assembly' | 'incoming-orders';

export const allWorkCenters: WorkCenter[] = ['laser-cut', 'folding', 'welding', 'painting', 'assembly', 'incoming-orders'];

interface ConfigState {
  workCenters: WorkCenter[];
}

const initialState: ConfigState = {
  workCenters: [],
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setWorkCenters: (state, action: PayloadAction<WorkCenter[]>) => {
      state.workCenters = action.payload;
    },
  },
});

export const { setWorkCenters } = configSlice.actions;

export const workCentersState = (state: RootState) => state.config.workCenters;
export const workCenterEnabledState = (workCenter: WorkCenter) => (state: RootState) => state.config.workCenters.includes(workCenter);

export default configSlice.reducer;
