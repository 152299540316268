import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { IncomingMaterial, Order, OrderMaterial } from '@api/types';

interface OrdersModals {
  addMaterial: boolean;
  createOrder: boolean;
  deleteOrder: boolean;
  confirmOrder: boolean;
  deleteOrderMaterial: boolean;
  editIncomingMaterial: boolean;
  editOrderMaterial: boolean;
}

interface OrdersState {
  modals: OrdersModals;
  selectedOrder: Order | null;
  selectedIncomingMaterial: IncomingMaterial | null;
  selectedOrderId: string;
  selectedOrderMaterial: OrderMaterial | null;
  selectedSupplierId: string;
}

const initialState: OrdersState = {
  modals: {
    addMaterial: false,
    createOrder: false,
    deleteOrder: false,
    confirmOrder: false,
    deleteOrderMaterial: false,
    editIncomingMaterial: false,
    editOrderMaterial: false,
  },
  selectedIncomingMaterial: null,
  selectedOrder: null,
  selectedOrderId: '',
  selectedOrderMaterial: null,
  selectedSupplierId: '',
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = true;
    },
    closeModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = false;
    },
    setSelectedOrderId: (state, action: PayloadAction<string>) => {
      state.selectedOrderId = action.payload;
    },
    setSelectedSupplierId: (state, action: PayloadAction<string>) => {
      state.selectedSupplierId = action.payload;
    },
    setSelectedOrder: (state, action: PayloadAction<Order | null>) => {
      state.selectedOrder = action.payload;
    },
    setSelectedOrderMaterial: (state, action: PayloadAction<OrderMaterial | null>) => {
      state.selectedOrderMaterial = action.payload;
    },
    setSelectedIncomingMaterial: (state, action: PayloadAction<IncomingMaterial | null>) => {
      state.selectedIncomingMaterial = action.payload;
    },
  },
});

export const {
  closeModal,
  openModal,
  setSelectedIncomingMaterial,
  setSelectedOrder,
  setSelectedOrderId,
  setSelectedOrderMaterial,
  setSelectedSupplierId,
} = ordersSlice.actions;

export const isAddMaterialModalOpenState = (state: RootState) => state.orders.modals.addMaterial;
export const isConfirmOrderModalOpenState = (state: RootState) => state.orders.modals.confirmOrder;
export const isCreateOrderModalOpenState = (state: RootState) => state.orders.modals.createOrder;
export const isDeleteOrderMaterialModalOpenState = (state: RootState) => state.orders.modals.deleteOrderMaterial;
export const isDeleteOrderModalOpenState = (state: RootState) => state.orders.modals.deleteOrder;
export const isEditIncomingMaterialModalOpenState = (state: RootState) => state.orders.modals.editIncomingMaterial;
export const isEditOrderMaterialModalOpenState = (state: RootState) => state.orders.modals.editOrderMaterial;
export const selectedIncomingMaterialState = (state: RootState) => state.orders.selectedIncomingMaterial;
export const selectedOrderIdState = (state: RootState) => state.orders.selectedOrderId;
export const selectedOrderMaterialState = (state: RootState) => state.orders.selectedOrderMaterial;
export const selectedOrderState = (state: RootState) => state.orders.selectedOrder;
export const selectedSupplierIdState = (state: RootState) => state.orders.selectedSupplierId;

export default ordersSlice.reducer;
