import React from 'react';
import { Spin, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { useGetSuppliers } from '@api/suppliers/useGetSuppliers';
import { Supplier } from '@api/types';
import { useAppDispatch } from '@hooks/redux';
import { setSelectedSupplier } from '@store/suppliers';

export function SuppliersTable() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetSuppliers();

  const onEdit = (supplier: Supplier) => {
    dispatch(setSelectedSupplier(supplier));
    navigate(`/registry/suppliers/${supplier.id}`);
  };

  const columns: ColumnProps<Supplier>[] = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Table
        columns={columns}
        dataSource={data}
        onRow={(row) => {
          return {
            onClick: () => onEdit(row),
            style: { cursor: 'pointer' },
          };
        }}
        pagination={{
          pageSizeOptions: ['10', '20', '30', '50'],
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
          total: data?.length ?? 0,
        }}
        rowKey="id"
        size="small"
      />
    </Spin>
  );
}
