import { Commission, Quotation } from '@api/types';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { useMutation, useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import { simplePost } from '@api/utils';
import React from 'react';

async function getCommissions(q?: string): Promise<Commission[]> {
  const response = await axios.get<Commission[]>(`${apiBaseUrl}/${ApiPath.Administration}/commissions?q=${q ?? ''}`);
  return response.data;
}

export function useGetCommissions(q?: string) {
  return useQuery({
    queryKey: [QueryKey.GetAdministrationCommissions, q],
    queryFn: () => getCommissions(q),
  });
}

async function downloadCsv(commissions: React.Key[]): Promise<void> {
  const response = await axios.post(`${apiBaseUrl}/${ApiPath.Administration}/commissions/export-csv`, commissions, { responseType: 'blob' });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;

  link.setAttribute('download', 'export-commissioni.csv');
  document.body.appendChild(link);
  link.click();
}

export function useExportCsv() {
  return useMutation({
    mutationKey: [QueryKey.AdministrationExportCommissionsCsv],
    mutationFn: (data: React.Key[]) => downloadCsv(data),
  })
}
