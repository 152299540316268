import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserRole } from '@api/types';

interface PrivateRouteProps {
  requiredRoles: string[];
  userRoles: UserRole[];
  children: React.ReactNode;
}

export function PrivateRoute({ requiredRoles, userRoles, children }: PrivateRouteProps) {
  const hasAccess = requiredRoles.some((role) => userRoles.map(r => r.role).includes(role));

  return hasAccess ? <>{children}</> : <Navigate to="/unauthorized" replace />;
}
