export enum OrdersKeys {
  CreateOrder = 'CreateOrder',
  CreateOrderMaterial = 'CreateOrderMaterial',
  DeleteOrder = 'DeleteOrder',
  DeleteOrderMaterial = 'DeleteOrderMaterial',
  DownloadOrderPdf = 'DownloadOrderPdf',
  GetIncomingOrder = 'GetIncomingOrder',
  GetIncomingOrders = 'GetIncomingOrders',
  GetOrder = 'GetOrder',
  GetOrderMaterials = 'GetOrderMaterials',
  GetOrders = 'GetOrders',
  SetArrivedQuantity = 'SetArrivedQuantity',
  UpdateOrder = 'UpdateOrder',
}
