import { OrdersKeys } from '@constants/keys/OrdersKeys';
import { Referrers } from '@constants/keys/Referrers';
import { TicketsCustomer } from '@constants/keys/TicketsCustomer';
import { TicketMessage } from '@constants/keys/TicketMessage';
import { TicketsManager } from '@constants/keys/TicketsManager';
import { Iot } from '@constants/keys/Iot';
import { Assets } from '@constants/keys/Assets';
import { TicketType } from '@constants/keys/TicketType';
import { Commissions } from '@constants/keys/Commissions';

export enum AllKey {
  // customers
  GetCustomers = 'GetCustomers',
  GetCustomer = 'GetCustomer',
  UpdateCustomer = 'UpdateCustomer',
  CreateCustomer = 'CreateCustomer',
  DeleteCustomer = 'DeleteCustomer',
  CreateCustomerPhoneNumber = 'CreateCustomerPhoneNumber',
  UpdateCustomerPhoneNumber = 'UpdateCustomerPhoneNumber',
  DeleteCustomerPhoneNumber = 'DeleteCustomerPhoneNumber',

  // products
  GetProducts = 'GetProducts',
  GetProduct = 'GetProduct',
  CreateProduct = 'CreateProduct',

  // quotations
  CreateQuotation = 'CreateQuotation',
  CreateQuotationProduct = 'CreateQuotationProduct',
  CreateQuotationSource = 'CreateQuotationSource',
  DeleteQuotationReferrer = 'DeleteQuotationReferrer',
  DeleteQuotationSource = 'DeleteQuotationSource',
  GetQuotation = 'GetQuotation',
  GetQuotationFiles = 'GetQuotationFiles',
  DeleteQuotationFile = 'DeleteQuotationFile',
  GetQuotationSources = 'GetQuotationSources',
  GetQuotationStatuses = 'GetQuotationStatuses',
  GetQuotations = 'GetQuotations',
  UpdateQuotation = 'UpdateQuotation',
  UpdateQuotationProduct = 'UpdateQuotationProduct',
  UpdateQuotationProductParam = 'UpdateQuotationProductParam',
  UpdateQuotationSource = 'UpdateQuotationSource',
  DownloadQuotationPdf = 'DownloadQuotationPdf',

  // list options
  GetListOptionTypes = 'GetListOptionTypes',
  GetListOptions = 'GetListOptions',
  UpdateListOption = 'UpdateListOption',

  // users
  GetProfile = 'GetProfile',
  GetUser = 'GetUser',
  GetUsers = 'GetUsers',
  UpdateUser = 'UpdateUser',
  UserChangePassword = 'UserChangePassword',
  GetUserDailyCheck = 'GetUserDailyCheck',
  UserCheckIn = 'UserCheckIn',
  UserCheckOut = 'UserCheckOut',
  TimeBreakStart = 'TimeBreakStart',
  TimeBreakEnd = 'TimeBreakEnd',

  // auth
  Register = 'Register',

  // work-orders
  GetWorkOrders = 'GetWorkOrders',
  GetWorkOrderById = 'GetWorkOrderById',
  CreateWorkOrder = 'CreateWorkOrder',
  WorkOrderAddCommission = 'WorkOrderAddCommission',
  WocActivateAllWorkPhases = 'WocActivateAllWorkPhases',
  WocUpdatePhase = 'WocUpdatePhase',
  StartWorkOrder = 'StartWorkOrder',
  GetWorkOrderByPhaseKey = 'GetWorkOrderByPhaseKey',
  GetWorkOrderByIdAndPhase = 'GetWorkOrderByIdAndPhase',
  WorkOrderCommissionPhase = 'WorkOrderCommissionPhase',
  GetCompletedWorkOrders = 'GetCompletedWorkOrders',
  GetWorkOrderPhaseCommission = 'GetWorkOrderPhaseCommission',

  // work-phases
  GetWorkPhases = 'GetWorkPhases',

  // warehouse
  GetWarehouses = 'GetWarehouses',
  GetWarehouseSupplies = 'GetWarehouseSupplies',
  AddWarehouseMaterial = 'AddWarehouseMaterial',
  WarehouseLoading = 'WarehouseLoading',
  DeleteWarehouseMaterial = 'DeleteWarehouseMaterial',

  // suppliers
  GetSupplier = 'GetSupplier',
  GetSuppliers = 'GetSuppliers',
  CreateSupplier = 'CreateSupplier',
  UpdateSupplier = 'UpdateSupplier',
  DeleteSupplier = 'DeleteSupplier',
  GetSupplierMaterials = 'GetSupplierMaterials',
  GetMaterialSuppliers = 'GetMaterialSuppliers',
  CreateSupplierMaterial = 'CreateSupplierMaterial',
  UpdateSupplierMaterial = 'UpdateSupplierMaterial',
  DeleteSupplierMaterial = 'DeleteSupplierMaterial',
  GetSupplierMaterial = 'GetSupplierMaterial',

  // materials
  GetMaterials = 'GetMaterials',
  GetMaterial = 'GetMaterial',
  UpdateMaterial = 'UpdateMaterial',

  // workers
  GetWorkers = 'GetWorkers',

  // notifications
  GetNotifications = 'GetNotifications',

  // administration
  GetAdministrationCommissions = 'GetAdministrationCommissions',
  AdministrationExportCommissionsCsv = 'AdministrationExportCommissionsCsv',

  // payment modes
  GetPaymentModes = 'GetPaymentModes',
}



export const QueryKey = {
  ...Commissions,
  ...OrdersKeys,
  ...Referrers,
  ...TicketsCustomer,
  ...TicketsManager,
  ...TicketMessage,
  ...TicketMessage,
  ...TicketType,
  ...Iot,

  ...Assets,

  ...AllKey
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
// export type QueryKey = typeof QueryKey;
