import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import { AddCommissionMaterialBody } from '@api/types';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';

export function useAddCommissionMaterial() {
  return useMutation({
    mutationKey: [QueryKey.AddCommissionMaterial],
    mutationFn: (body: AddCommissionMaterialBody) =>
      axios.post(`${apiBaseUrl}/${ApiPath.Commissions}/${body.commissionId}/materials`, body),
  })
}
