import { Col, Form, Modal, Select } from 'antd';
import { useGetQuotations } from '@api/quotations';
import { useCreateCommission } from '@api/commissions/useCreateCommission';
import { useEffect } from 'react';
import { CreateCommissionRequest } from '@api/types';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { closeModal, isCreateCommissionModalOpenState } from '@store/commissions';
import { useNavigate } from 'react-router-dom';

export function CreateCommissionModal() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const open = useAppSelector(isCreateCommissionModalOpenState);

  const { data: quotations } = useGetQuotations({ withoutCommission: true });

  const {
    mutate: createCommission,
    isSuccess: isSuccessCreateCommission,
    reset: resetCreateCommissions,
    data,
  } = useCreateCommission();

  const options = quotations?.map(q => ({ label: `${q.year}-${q.serial}`, value: q.id })) ?? [];

  const onFinish = (values: CreateCommissionRequest) => {
    createCommission(values);
  };

  const onCancel = () => {
    dispatch(closeModal('createCommission'));
    form.resetFields();
  };

  useEffect(() => {
    if (isSuccessCreateCommission) {
      resetCreateCommissions();
      dispatch(closeModal('createCommission'));
      navigate(`/commissions/${data.data.id}`)
    }
  }, [isSuccessCreateCommission]);

  return (
    <Modal title="Nuova Commessa" open={open} onOk={() => form.submit()} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="create-commission" layout="vertical" onFinish={onFinish}>
          <Form.Item name="quotationId" label="Preventivo">
            <Select options={options} />
          </Form.Item>
        </Form>
      </Col>
    </Modal>
  );
}
