import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { Customer, CustomerPhoneNumber } from '@api/types';

interface CustomersModals {
  deleteCustomer: boolean;
  createCustomerPhoneNumber: boolean;
  editCustomerPhoneNumber: boolean;
  deleteCustomerPhoneNumber: boolean;
}

interface CustomersState {
  modals: CustomersModals;
  selectedCustomerId: string;
selectedCustomer: Customer | null;
  selectedCustomerPhoneNumber: CustomerPhoneNumber | null;
}

const initialState: CustomersState = {
  modals: {
    deleteCustomer: false,
    createCustomerPhoneNumber: false,
    editCustomerPhoneNumber: false,
    deleteCustomerPhoneNumber: false,
  },
  selectedCustomerId: '',
  selectedCustomer: null,
  selectedCustomerPhoneNumber: null,
};

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = true;
    },

    closeModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = false;
    },

    setSelectedCustomerId: (state, action: PayloadAction<string>) => {
      state.selectedCustomerId = action.payload;
    },

    setSelectedCustomer: (state, action: PayloadAction<Customer | null>) => {
      state.selectedCustomer = action.payload;
    },

    setSelectedCustomerPhoneNumber: (state, action: PayloadAction<CustomerPhoneNumber | null>) => {
      state.selectedCustomerPhoneNumber = action.payload;
    }
  },
});

export const { openModal, closeModal, setSelectedCustomerId, setSelectedCustomer, setSelectedCustomerPhoneNumber } = customersSlice.actions;

export const customerModalsState = (state: RootState) => state.customers.modals;
export const isDeleteCustomerModalOpenState = (state: RootState) => state.customers.modals.deleteCustomer;

export const selectedCustomerIdState = (state: RootState) => state.customers.selectedCustomerId;
export const selectedCustomerState = (state: RootState) => state.customers.selectedCustomer;
export const selectedCustomerPhoneNumberState = (state: RootState) => state.customers.selectedCustomerPhoneNumber;

export default customersSlice.reducer;
