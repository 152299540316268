export enum Assets {
  GetAssets = 'GetAssets',
  GetAsset = 'GetAsset',
  GetAssetAttributes = 'GetAssetAttributes',
  CreateAsset = 'CreateAsset',
  UpdateAsset = 'UpdateAsset',
  CreateAssetAttribute = 'CreateAssetAttribute',
  CreateMaintenance = 'CreateMaintenance',
  GetMaintenances = 'GetMaintenances',
  GetMaintenance = 'GetMaintenance',
  GetMaintenanceActivities = 'GetMaintenanceActivities',
  CreateMaintenanceActivity = 'CreateMaintenanceActivity',
}
