import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { Commission, CommissionMaterial } from '@api/types';

interface CommissionsModals {
  addMaterial: boolean;
  updateMaterial: boolean;
  createCommission: boolean;
}

interface CommissionsState {
  modals: CommissionsModals;
  selectedCommission: Commission | null;
  selectedMaterial: CommissionMaterial | null;
}

const initialState: CommissionsState = {
  modals: {
    addMaterial: false,
    updateMaterial: false,
    createCommission: false,
  },
  selectedCommission: null,
  selectedMaterial: null,
};


export const commissionsSlice = createSlice({
  name: 'commissions',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = true;
    },
    closeModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = false;
    },
    setSelectedCommission: (state, action: PayloadAction<Commission | null>) => {
      state.selectedCommission = action.payload;
    },
    setSelectedMaterial: (state, action: PayloadAction<CommissionMaterial | null>) => {
      state.selectedMaterial = action.payload;
    }
  },
});

export const { openModal, closeModal, setSelectedCommission, setSelectedMaterial } = commissionsSlice.actions;

export const selectedCommissionState = (state: RootState) => state.commissions.selectedCommission;
export const selectedMaterialState = (state: RootState) => state.commissions.selectedMaterial;

export const commissionsModalsState = (state: RootState) => state.commissions.modals;

export const isCreateCommissionModalOpenState = (state: RootState) => state.commissions.modals.createCommission;

export default commissionsSlice.reducer;
