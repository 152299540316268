import {
  Avatar,
  Breadcrumb,
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
  theme,
  Typography,
} from 'antd';
import { getWorkOrderStatusLabel } from '@routes/work-orders/utils';
import { useGetWorkOrderByIdAndPhase, useWorkOrderCommissionPhase } from '@api/work-orders';
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { ColumnProps } from 'antd/es/table';
import { Ticket, WorkOrderCommission } from '@api/types';
import { FileTextOutlined, SettingOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { activeColumnsState, setActiveColumns } from '@store/work-orders';
import { useCreateCustomerTicket } from '@api/tickets';
import { format } from 'date-fns';
import { DateFormat } from '@constants/constants';
import { useGetTicketTypes } from '@api/ticket-types';
import { useGetWorkPhases } from '@api/work-phases';

const NoWrap = ({ text }: { text: string }) => (
  <Typography style={{ textWrap: 'nowrap' }}>{text}</Typography>
);

const activeColumnsOptions = [
  { value: 'customer', label: 'Cliente' },
  { value: 'reference', label: 'Riferimento' },
  { value: 'colorBody', label: 'Colore Scocca' },
  { value: 'colorExternalFrame', label: 'Colore Telaio Esterno' },
  { value: 'colorInternalFrame', label: 'Colore Telaio Interno' },
  { value: 'littleLightColor', label: 'Colore Antina' },
  { value: 'doorTypeVersion', label: 'Versione' },
  { value: 'doorLight', label: 'Luce Porta' },
  { value: 'falseFrameMaxSize', label: 'Ing Max Falso Telaio' },
  { value: 'escutcheons', label: 'Numero Mostrine' },
  { value: 'note', label: 'Note' },
];

interface Props {
  workPhaseKey: string;
}

enum Tab {
  Commissions = 'commissions',
  Tickets = 'tickets',
}

export function WorkPhaseOrder({ workPhaseKey }: Props) {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<Tab>(Tab.Commissions);
  const activeColumns = useAppSelector(activeColumnsState(workPhaseKey + '-commissions'));
  const [isActiveColumnsOpen, setActiveColumnsOpen] = useState(false);
  const [isOpenNoteModal, setIsOpenNoteModal] = useState(false);
  const [isOpenCreateTicketModal, setIsOpenCreateTicketModal] = useState(false);
  const [note, setNote] = useState('');
  const { token } = theme.useToken();
  const [createTicketForm] = Form.useForm();

  const { data: workOrder, refetch: refetchWorkOrder } = useGetWorkOrderByIdAndPhase(params?.id ?? '', workPhaseKey);

  const {
    mutate: workOrderCommissionPhase,
    isSuccess: isSuccessWorkOrderCommissionPhase,
    reset: resetWorkOrderCommissionPhase,
  } = useWorkOrderCommissionPhase();

  const {
    mutate: createTicket,
    data: createTicketData,
    isSuccess: isSuccessCreateTicket,
    reset: resetCreateTicket,
  } = useCreateCustomerTicket();

  const { data: ticketTypes } = useGetTicketTypes();
  const { data: workPhases } = useGetWorkPhases();

  console.log('wp', workPhases);

  const onStart = (id: string) => {
    workOrderCommissionPhase({ action: 'start', phaseId: id });
  };

  const onStop = (id: string) => {
    workOrderCommissionPhase({ action: 'stop', phaseId: id });
  };

  const onEnd = (id: string) => {
    workOrderCommissionPhase({ action: 'end', phaseId: id });
  };

  const onNewTicket = () => {
    setIsOpenCreateTicketModal(true);
  };

  const onOkCreateTicket = () => {
    createTicketForm.submit();
  };

  const onCancelCreateTicket = () => {
    createTicketForm.resetFields();
    setIsOpenCreateTicketModal(false);
  };

  const onFinishCreateTicket = (values: Pick<Ticket, 'subject' | 'workOrderCommissionId'>) => {
    createTicket({
      ...values,
      workOrderId: workOrder?.id ?? '',
      workPhaseId: workPhases?.find(wp => wp.key === workPhaseKey)?.id ?? undefined,
    });
  };

  const renderExtra = useMemo(() => {
    switch (activeTab) {
      case Tab.Commissions:
        return (
          <Button type="text" onClick={() => setActiveColumnsOpen(true)}><SettingOutlined /></Button>
        );
      case Tab.Tickets:
        return (
          <Button type="primary" onClick={onNewTicket}>Nuova Segnalazione</Button>
        );
    }
  }, [activeTab]);

  useEffect(() => {
    if (isSuccessWorkOrderCommissionPhase) {
      resetWorkOrderCommissionPhase();
      refetchWorkOrder();
    }
  }, [isSuccessWorkOrderCommissionPhase]);

  useEffect(() => {
    if (isSuccessCreateTicket) {
      resetCreateTicket();
      setIsOpenCreateTicketModal(false);
      createTicketForm.resetFields();
      navigate(`/tickets/${createTicketData.id}`);
    }
  }, [isSuccessCreateTicket]);

  const items = [
    {
      title: <Link
        to={`/${workPhaseKey}`}
        style={{ marginInline: 'unset' }}
      >{getWorkOrderStatusLabel(workPhaseKey)}</Link>,
    },
    { title: workOrder?.serial ?? '' },
  ];

  const columns = useMemo<ColumnProps<WorkOrderCommission>[]>(
    () => {
      const columns: ColumnProps<WorkOrderCommission>[] = [
        {
          title: 'Stato',
          key: 'status',
          render: (_, record) => <Avatar
            size={14}
            style={{ backgroundColor: record.currentPhase.started ? token.colorWarningText : record.phases.find(p => p.key === workPhaseKey)?.completed ? token.green : token.colorFill }}
          />,
        },
        {
          title: 'Commessa',
          key: 'commission',
          dataIndex: 'serial',
        },
      ];

      if (activeColumns?.customer) {
        columns.push(
          {
            title: 'Cliente',
            key: 'customer',
            render: (_, record) => <NoWrap text={record.quotation?.customer?.name ?? ''} />,
          },
        );
      }

      if (activeColumns?.reference) {
        columns.push(
          {
            title: 'Riferimento',
            key: 'reference',
            render: (_, record) => <NoWrap text={record.quotation?.reference ?? ''} />,
          },
        );
      }

      if (activeColumns?.colorBody) {
        columns.push(
          {
            title: 'Colore Scocca',
            key: 'color-body',
            render: (_, record) =>
              <NoWrap text={record.quotation?.product?.options?.find(po => po.key === 'color')?.params?.find(p => p.key === 'body')?.value ?? ''} />,
          },
        );
      }

      if (activeColumns?.colorExternalFrame) {
        columns.push(
          {
            title: 'Colore Telaio Esterno',
            key: 'color-external-frame',
            render: (_, record) =>
              <NoWrap text={record.quotation?.product?.options?.find(po => po.key === 'color')?.params?.find(p => p.key === 'external-frame')?.value ?? ''} />,
          },
        );
      }

      if (activeColumns?.colorInternalFrame) {
        columns.push(
          {
            title: 'Colore Telaio Interno',
            key: 'color-internal-frame',
            render: (_, record) =>
              <NoWrap text={record.quotation?.product?.options?.find(po => po.key === 'color')?.params?.find(p => p.key === 'internal-frame')?.value ?? ''} />,
          },
        );
      }

      if (activeColumns?.littleLightColor) {
        columns.push(
          {
            title: 'Colore Antina',
            key: 'little-light-color',
            render: (_, record) =>
              <NoWrap text={record.quotation?.product?.options?.find(po => po.key === 'little-light')?.params?.find(p => p.key === 'color')?.value ?? ''} />,
          },
        );
      }

      if (activeColumns?.doorTypeVersion) {
        columns.push(
          {
            title: 'Versione',
            key: 'door-type-version',
            render: (_, record) =>
              <NoWrap text={record.quotation?.product?.options?.find(po => po.key === 'door-type')?.params?.find(p => p.key === 'version')?.value ?? ''} />,
          },
        );
      }

      if (activeColumns?.doorLight) {
        columns.push(
          {
            title: 'Luce Porta',
            key: 'door-light',
            render: (_, record) => {
              const params = record.quotation?.product?.options?.find(po => po.key === 'door-light')?.params;

              return <NoWrap text={`${params?.[0]?.value ?? ''}${params?.[0]?.value && params?.[1]?.value ? ' * ' : ''}${params?.[1]?.value ?? ''}`} />;
            },
          },
        );
      }

      if (activeColumns?.falseFrameMaxSize) {
        columns.push(
          {
            title: 'Ing Max Falso Telaio',
            key: 'false-frame-max-size',
            render: (_, record) => {
              const params = record.quotation?.product?.options?.find(po => po.key === 'false-frame-max-size')?.params;

              return <NoWrap text={`${params?.[0]?.value ?? ''}${params?.[0]?.value && params?.[1]?.value ? ' * ' : ''}${params?.[1]?.value ?? ''}`} />;
            },
          },
        );
      }

      if (activeColumns?.escutcheons) {
        columns.push(
          {
            title: 'Numero Mostrine',
            key: 'escutcheons',
            render: (_, record) =>
              <NoWrap text={record.quotation?.product?.options?.find(po => po.key === 'escutcheons')?.params?.find(p => p.key === 'quantity')?.value ?? ''} />,
          },
        );
      }

      if (activeColumns?.note) {
        columns.push(
          {
            title: 'Note',
            key: 'note',
            render: (_, record) => !!record.note ? <FileTextOutlined
              onClick={(e) => {
                e.stopPropagation();
                setNote(record.note);
                setIsOpenNoteModal(true);
              }} style={{ fontSize: 24, cursor: 'pointer' }}
            /> : '',
          },
        );
      }

      columns.push(
        {
          title: 'Quantità',
          key: 'quantity',
          render: (_, record) => record.quotation.product.quantity ?? '',
        },
      );

      columns.push(
        {
          title: 'Azioni Rapide',
          key: 'actions',
          render: (_, record) => (
            <Space size="middle">
              <Button
                type="link"
                onClick={e => {
                  e.stopPropagation();
                  onStart(record.phases.find(p => p.key === workPhaseKey)?.id ?? '');
                }}
              >Avvia</Button>
              <Button
                type="link"
                onClick={e => {
                  e.stopPropagation();
                  onStop(record.phases.find(p => p.key === workPhaseKey)?.id ?? '');
                }}
              >Ferma</Button>
              <Button
                type="link"
                onClick={e => {
                  e.stopPropagation();
                  onEnd(record.phases.find(p => p.key === workPhaseKey)?.id ?? '');
                }}
              >Completa</Button>
            </Space>
          ),
          align: 'right',
        },
      );

      return columns;
    },
    [activeColumns],
  );

  const ticketsColumns: ColumnProps<Ticket>[] = [
    {
      title: 'Seriale',
      key: 'serial',
      dataIndex: 'serial',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (record) => record === 'open' ? 'Aperto' : 'Chiuso',
    },
    {
      title: 'Oggetto',
      key: 'subject',
      dataIndex: 'subject',
    },
    {
      title: 'Utente',
      key: 'user',
      dataIndex: 'user',
    },
    {
      title: 'Data Creazione',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (record) => format(record, DateFormat),
    },
  ];

  const tabList = [
    {
      key: 'commissions',
      label: 'Commesse',
    },
    {
      key: 'files',
      label: 'Files',
    },
    {
      key: 'tickets',
      label: 'Segnalazioni',
    },
  ];

  return (
    <Row>
      {/*{workOrder?.commissions.map(c => (*/}
      {/*  <Col span={12}>*/}
      {/*    <Card>*/}
      {/*      <Row gutter={16}>*/}
      {/*        <Col span={4}>*/}
      {/*          <Statistic title="Seriale Commessa" value={`${c.year}-${c.serial}`} />*/}
      {/*        </Col>*/}
      {/*        <Col span={12}>*/}
      {/*          <Statistic title="Cliente" value={c.quotation?.customer?.name} />*/}
      {/*        </Col>*/}
      {/*        <Col span={8}>*/}
      {/*          <Statistic title="Riferimento" value={c.quotation?.reference} />*/}
      {/*        </Col>*/}
      {/*      </Row>*/}

      {/*      <Divider />*/}

      {/*      <Col span={12}>*/}
      {/*        <Statistic title="Account Balance (CNY)" value="sasa" />*/}
      {/*      </Col>*/}
      {/*    </Card>*/}
      {/*  </Col>*/}
      {/*))}*/}

      <Col span={24}>
        <Card
          title={<Breadcrumb items={items} />}
          extra={renderExtra}
          tabList={tabList}
          activeTabKey={activeTab}
          onTabChange={(key) => setActiveTab(key as Tab)}
        >
          {activeTab === Tab.Commissions && (
            <Table
              columns={columns}
              dataSource={workOrder?.commissions}
              scroll={{ x: true }}
              onRow={(row) => {
                return {
                  onClick: () => navigate(`/${workPhaseKey}/${workOrder?.id ?? ''}/${row.id}`),
                  style: { cursor: 'pointer' },
                };
              }}
              pagination={{
                pageSizeOptions: ['10', '20', '30', '50'],
                responsive: true,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
                total: workOrder?.commissions?.length ?? 0,
              }}
              rowKey="id"
              size="large"
            />
          )}

          {activeTab === Tab.Tickets && (
            <Table
              columns={ticketsColumns}
              dataSource={workOrder?.tickets}
              onRow={(row) => {
                return {
                  onClick: () => navigate(`/tickets/${row.id}`),
                  style: { cursor: 'pointer' },
                };
              }}
              pagination={{
                pageSizeOptions: ['10', '20', '30', '50'],
                responsive: true,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
                total: workOrder?.commissions?.length ?? 0,
              }}
              rowKey="id"
              size="large"
            />
          )}
        </Card>
      </Col>

      <Drawer
        title="Colonne Attive"
        placement="right"
        closable
        onClose={() => setActiveColumnsOpen(false)}
        open={isActiveColumnsOpen}
        key="activate-columns"
      >
        {activeColumnsOptions.map(o => (
          <Row justify="space-between" style={{ marginBottom: 12 }} key={workPhaseKey + '-commissions'}>
            <Typography>{o.label}</Typography>
            <Switch
              value={activeColumns?.[o.value] ?? false}
              onChange={e => dispatch(setActiveColumns({
                key: workPhaseKey + '-commissions',
                column: o.value,
                value: e,
              }))}
            />
          </Row>
        ))}
      </Drawer>

      <Modal
        title="Nota" open={isOpenNoteModal} onCancel={() => {
        setNote('');
        setIsOpenNoteModal(false);
      }} footer={null}
      >
        <Typography>{note}</Typography>
      </Modal>

      <Modal
        title="Nuova Segnalazione"
        open={isOpenCreateTicketModal}
        onOk={onOkCreateTicket}
        onCancel={onCancelCreateTicket}
      >
        <Form name="create-ticket-customer" form={createTicketForm} onFinish={onFinishCreateTicket} layout="vertical">
          <Form.Item name="typeId" label="Tipologia" rules={[{ required: true, message: 'Seleziona un tipo valido' }]}>
            <Select options={ticketTypes?.map(type => ({ label: type.name, value: type.id }))} />
          </Form.Item>

          <Form.Item name="subject" label="Oggetto">
            <Input.TextArea rows={10} />
          </Form.Item>

          <Form.Item name="workOrderCommissionId" label="Commissione">
            <Select options={workOrder?.commissions?.map(c => ({ label: c.serial, value: c.id }))} />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
}
