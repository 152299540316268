import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { closeModal, isNewDeviceModalOpenState } from '@store/iot';
import { useCreateDevice, useGetDevices } from '@api/iot';
import { Alert, Col, Form, Input, Modal } from 'antd';
import React, { useEffect } from 'react';
import { CreateDeviceBody } from '@api/types';
import { getNewDeviceErrorLabel } from '@routes/iot/utils';

export function NewDeviceModal() {
  const dispatch = useAppDispatch();
  const open = useAppSelector(isNewDeviceModalOpenState);

  const { refetch } = useGetDevices();
  const { mutate, isSuccess, isPending, reset, error } = useCreateDevice();

  const [form] = Form.useForm();

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      reset();
      refetch();
      close();
    }
  }, [isSuccess]);

  const close = () => dispatch(closeModal('newDevice'));

  const onOk = () => {
    form.submit();
  };

  const onFinish = (values: CreateDeviceBody) => {
    mutate(values);
  };

  return (
    <Modal title="Nuovo Device" open={open} onCancel={close} onOk={onOk}>
      <Form form={form} onFinish={onFinish} id="new-device" name="new-device" layout="vertical">
        <Form.Item name="ip" label="IP Address">
          <Input disabled={isPending} />
        </Form.Item>
      </Form>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={getNewDeviceErrorLabel(error?.response?.data.message)}
            key="delete-referrer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
