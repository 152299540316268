import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useAppSelector } from '@hooks/redux';
import { isLoggedInState } from '@store/auth';
import { PrivateRoutes } from './PrivateRoutes';
import { PublicRoutes } from './PublicRoutes';

export function RootRouter() {
  const isLoggedIn = useAppSelector(isLoggedInState);

  return (
    <BrowserRouter>
      {isLoggedIn ? <PrivateRoutes /> : <PublicRoutes />}
    </BrowserRouter>
  );
}
