import { Form, Input, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { closeModal, isUpdateAssetModalOpenState, selectedAssetState } from '@store/assets';
import { useGetAsset, useUpdateAsset } from '@api/assets';
import { Asset } from '@api/types/assets';
import { useEffect } from 'react';

export function UpdateAssetModal() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const open = useAppSelector(isUpdateAssetModalOpenState);
  const asset = useAppSelector(selectedAssetState);

  const { mutate, isSuccess, reset } = useUpdateAsset();
  const { refetch } = useGetAsset(asset?.id ?? '');

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      reset();
      refetch();
      close();
    }
  }, [isSuccess]);

  const close = () => dispatch(closeModal('updateAsset'));

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => {
    close();
  };

  const onFinish = (values: Pick<Asset, 'name'>) => {
    if (asset) {
      mutate({ id: asset.id, ...values });
    }
  };

  return (
    <Modal open={open} onOk={onOk} onCancel={onCancel} styles={{ body: { paddingTop: 8, paddingBottom: 32 } }}>
      <Form name="create-asset" form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="name"
          label="Nome"
          rules={[{ required: true, message: 'Inserisci un nome valido' }]}
          initialValue={asset?.name ?? ''}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
