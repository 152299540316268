import { ConfirmModal } from '@components/modals/ConfirmModal';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  closeModal,
  selectedMaterialState,
  selectedWarehouseIdState,
  setSelectedMaterial,
  warehouseModalsState,
} from '@store/warehouse';
import { useDeleteWarehouseMaterial } from '@api/warehouses';
import { useGetMaterials } from '@api/materials/useGetMaterials';
import { useEffect } from 'react';

interface Props {
  onSuccess?: () => void;
}

export function DeleteWarehouseMaterialModal({ onSuccess }: Props) {
  const dispatch = useAppDispatch();
  const { deleteMaterial: open } = useAppSelector(warehouseModalsState);

  const warehouseId = useAppSelector(selectedWarehouseIdState);
  const material = useAppSelector(selectedMaterialState);

  const { mutate, isSuccess, reset } = useDeleteWarehouseMaterial();
  const { refetch } = useGetMaterials();

  useEffect(() => {
    if (isSuccess) {
      reset();
      refetch();
      close();

      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    }
  }, [isSuccess]);

  const close = () => dispatch(closeModal('deleteMaterial'));

  const onOk = () => {
    if (material) {
      mutate({ warehouseId, materialId: material.id });
    }
  };

  const onCancel = () => {
    dispatch(setSelectedMaterial(null));
    close();
  };

  return (
    <ConfirmModal
      title="Elimina Materiale"
      message="Sei sicuro di voler eliminare il seguente materiale?"
      subject={material?.description ?? ''}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
    />
  );
}
