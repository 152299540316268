import { Alert, Button, Card, Col, Divider, Form, Input, Modal, Row, Space, Spin, Table, Typography } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteUser, useChangePassword, useGetUsers } from '@api/users';
import { useNotification } from '@hooks/useNotification';
import { useAppSelector } from '@hooks/redux';
import { userProfileState } from '@store/user';
import { useRegister } from '@api/auth';
import { useMutation } from '@tanstack/react-query';
import { CreateUserRequest, UserChangePasswordRequest, UserProfileResponse } from '@api/types';
import { ColumnProps } from 'antd/es/table';

export function UsersRoute() {
  const navigate = useNavigate();
  const [createUserForm] = Form.useForm();
  const [changePasswordForm] = Form.useForm();
  const { data, isLoading, refetch } = useGetUsers();
  const [isDisableUserModalOpen, setIsDisableUserModalOpen] = useState(false);
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>('');
  const notification = useNotification();

  const profile = useAppSelector(userProfileState);

  const { mutate: register, isSuccess: isSuccessRegister, data: registerData, error, isError } = useRegister();
  const {
    mutate: changePassword,
    isSuccess: isSuccessChangePassword,
    error: errorChangePassword,
    isError: isErrorChangePassword,
    reset: changePasswordReset,
  } = useChangePassword();

  const onAddClick = () => {
    setIsCreateUserModalOpen(true);
  };

  const onChangePasswordClick = (id: string) => {
    setSelectedUser(id);
    setIsChangePasswordModalOpen(true);
  };

  const onEditClick = (id: string) => {
    navigate(`/settings/users/${id}`);
  };

  const { mutate, isSuccess } = useMutation({
    mutationFn: deleteUser,
    mutationKey: ['delete-user'],
  });

  const showModal = (id: string) => {
    setSelectedUser(id);
    setIsDisableUserModalOpen(true);
  };

  const okOkDisableUser = () => {
    mutate(selectedUser);
    setSelectedUser('');
    setIsDisableUserModalOpen(false);
  };

  const onCancelDisableUser = () => {
    setSelectedUser('');
    setIsDisableUserModalOpen(false);
  };

  const onOkCreateUser = () => {
    createUserForm.submit();
  };

  const onFinishCreateUser = (user: CreateUserRequest) => {
    register(user);
  };

  const onCancelCreateUser = () => {
    setIsCreateUserModalOpen(false);
    setSelectedUser('');
  };

  const onOkChangePassword = () => {
    changePasswordForm.submit();
  };

  const onCancelChangePassword = () => {
    setIsChangePasswordModalOpen(false);
  };

  const onFinishChangePassword = (values: UserChangePasswordRequest) => {
    changePassword({ id: selectedUser, data: values });
  };

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessChangePassword) {
      setIsChangePasswordModalOpen(false);
      changePasswordReset();
      changePasswordForm.resetFields();
      notification.openInfoMessage('Password aggiornata con successo');
    }
  }, [isSuccessChangePassword]);

  useEffect(() => {
    if (isSuccessRegister && registerData.data.id) {
      navigate(`/settings/users/${registerData.data.id}`);
    }
  }, [isSuccessRegister, registerData?.data.id]);

  const columns: ColumnProps<UserProfileResponse>[] = useMemo(() => [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Nome e Cognome',
      dataIndex: 'name',
      render: (_, record) => `${record.name} ${record.surname}`,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Telefono',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onChangePasswordClick(record.id)}>Cambia Password</Button>
          <Button type="link" onClick={() => onEditClick(record.id)}>Modifica</Button>
          <Button
            type="link"
            onClick={() => showModal(record.id)}
            disabled={record.id === profile?.id}
          >{record.enabled ? 'Disabilita' : 'Abilita'}</Button>
        </Space>
      ),
      align: 'right',
    },
  ], []);

  return (
    <Row>
      <Col span={24}>
        <Card title="Users">
          <Row justify="end" style={{ marginTop: '24px', marginBottom: '36px' }}>
            <Button type="primary" icon={<PlusCircleOutlined />} onClick={onAddClick}>
              Aggiungi Utente
            </Button>
          </Row>

          <Spin spinning={isLoading}>
            <Table
              columns={columns}
              dataSource={data}
              pagination={{
                pageSizeOptions: ['10', '20', '30', '50'],
                responsive: true,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
                total: data?.length ?? 0,
              }}
              rowKey="id"
              size="small"
            />
          </Spin>

          <Modal
            title="Disabilita Utente"
            open={isDisableUserModalOpen}
            onOk={okOkDisableUser}
            onCancel={onCancelDisableUser}
          >
            <Typography>Sei sicuro di
              voler {data?.find(user => user.id === selectedUser)?.enabled ? 'disabilitare' : 'abilitare'} l'utente <b>{data?.find(user => user.id === selectedUser)?.username}</b>?</Typography>
            <Divider style={{ borderColor: 'transparent' }} />
            <Alert
              message={data?.find(user => user.id === selectedUser)?.enabled ? 'Attenzione! l\'utente perderà ogni accesso alla piattaforma' : 'Attenzione! L\'utente riacquisirà gli accessi alla piattaforma'}
              type="warning"
            />
            <Divider style={{ borderColor: 'transparent', marginTop: 0 }} />
          </Modal>

          <Modal title="Crea Utente" open={isCreateUserModalOpen} onOk={onOkCreateUser} onCancel={onCancelCreateUser}>
            <Form
              form={createUserForm}
              name="create-user"
              onFinish={onFinishCreateUser}
              layout="vertical"
              style={{ marginTop: 20, marginBottom: 60 }}
            >
              <Form.Item
                name="username"
                label="username"
                required
                rules={[{ required: true, message: 'Per favore inserisci un nome utente' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="password"
                label="password"
                required
                rules={[{ required: true, message: 'Per favore inserisci una password valida' }]}
              >
                <Input />
              </Form.Item>

              {isError && Array.isArray(error?.response?.data?.message) && (
                <Col>
                  {error?.response?.data?.message?.map(e => (
                    <Alert
                      message={e}
                      key={e}
                      type="error"
                      style={{ visibility: isError ? 'visible' : 'hidden', margin: 0, marginBottom: 12 }}
                      showIcon
                    />
                  ))}
                </Col>
              )}
            </Form>
          </Modal>

          <Modal
            title="Cambia Password"
            open={isChangePasswordModalOpen}
            onOk={onOkChangePassword}
            onCancel={onCancelChangePassword}
          >
            <Form
              form={changePasswordForm}
              name="change-password"
              layout="vertical"
              onFinish={onFinishChangePassword}
              style={{ marginTop: 40 }}
            >
              <Form.Item name="password" label="Password">
                <Input type="password" />
              </Form.Item>

              <Form.Item name="confirmPassword" label="Conferma Password">
                <Input type="password" />
              </Form.Item>

              {isErrorChangePassword && Array.isArray(errorChangePassword?.response?.data?.message) && (
                <Col>
                  {errorChangePassword?.response?.data?.message?.map(e => (
                    <Alert
                      message={e}
                      key={e}
                      type="error"
                      style={{ visibility: isErrorChangePassword ? 'visible' : 'hidden', margin: 0, marginBottom: 12 }}
                      showIcon
                    />
                  ))}
                </Col>
              )}
            </Form>
            {notification.contextHolder}
          </Modal>
        </Card>
      </Col>
    </Row>
  );
}
