import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import { simpleGet } from '@api/utils';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';

export interface PaymentMode {
  id: string;
  name: string;
}

export function useGetPaymentModes() {
  return useQuery({
    queryKey: [QueryKey.GetPaymentModes],
    queryFn: () => simpleGet<PaymentMode[]>(`${apiBaseUrl}/${ApiPath.PaymentModes}`)
  })
}
