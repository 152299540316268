import { Form, Input, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  closeModal,
  customerModalsState,
  selectedCustomerPhoneNumberState,
  selectedCustomerState,
  setSelectedCustomer, setSelectedCustomerPhoneNumber,
} from '@store/customers';
import { useUpdateCustomerPhoneNumber } from '@api/customers';
import { CustomerPhoneNumber } from '@api/types';
import { useGetCustomer } from '@api/customers/useGetCustomer';
import { useEffect } from 'react';

export function EditPhoneNumberModal() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { editCustomerPhoneNumber: open } = useAppSelector(customerModalsState);
  const customer = useAppSelector(selectedCustomerState);
  const phone = useAppSelector(selectedCustomerPhoneNumberState);

  const { mutate, isSuccess, reset } = useUpdateCustomerPhoneNumber()
  const { refetch } = useGetCustomer(customer?.id ?? '');

  const close = () => {
    form.resetFields();
    dispatch(setSelectedCustomer(null));
    dispatch(setSelectedCustomerPhoneNumber(null));
    dispatch(closeModal('editCustomerPhoneNumber'));
  };

  useEffect(() => {
    form.setFieldsValue(phone);
  }, [phone]);

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      reset();
      refetch();
      close()
    }
  }, [isSuccess]);

  const onOk = () => {
    form.submit();
  }

  const onFinish = (values: Pick<CustomerPhoneNumber, 'number' | 'note'>) => {
    if (customer) {
      mutate({
        ...values,
        id: phone?.id ?? '',
        customerId: customer.id,
      });
    }
  }

  return (
    <Modal title="Modifica Recapito Telefonico" open={open} onOk={onOk} onCancel={close} styles={{ body: { paddingTop: 8, paddingBottom: 32 } }}>
      <Form name="edit-customer-phone-number" form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="number" label="Numero" rules={[{ required: true, message: 'Per favore inserisci un numero valido' }]}>
          <Input />
        </Form.Item>

        <Form.Item name="note" label="note">
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
