import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Login } from '@routes/auth';
import { BaseLayout } from '../layouts/BaseLayout';

export function PublicRoutes() {
  return (
    <Routes>
      <Route path="/" element={<BaseLayout />}>
        <Route index element={<Login />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
}
