import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Badge, Layout, Menu, Row, theme, Typography } from 'antd';
import { MenuProps } from 'antd/lib';
import {
  BellOutlined, BulbOutlined,
  DashboardOutlined,
  GroupOutlined,
  LoginOutlined,
  PrinterOutlined,
  SettingOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';
import logo from '../../img/logo.png';
import { useAppSelector } from '@hooks/redux';
import { userRolesState } from '@store/user';
import { workCentersState } from '@store/config';

const { Sider } = Layout;

const dashboardItem = {
  key: 'dashboard',
  label: <Link to="/">Dashboard</Link>,
  icon: React.createElement(DashboardOutlined),
};

const registryItem = {
  key: 'registry',
  label: <Typography>Anagrafiche</Typography>,
  icon: React.createElement(PrinterOutlined),
  children: [
    { label: <Link to="/registry/products">Prodotti</Link>, key: 'products' },
    { label: <Link to="/registry/customers">Clienti</Link>, key: 'customers' },
    { label: <Link to="/registry/suppliers">Fornitori</Link>, key: 'suppliers' },
    { label: <Link to="/registry/materials">Materiali</Link>, key: 'materials' },
    { label: <Link to="/registry/referrers">Segnalatori</Link>, key: 'referrers' },
    { label: <Link to="/registry/sources">Provenienze</Link>, key: 'sources' },
    { label: <Link to="/registry/ticket-types">Tipi Segnalazioni</Link>, key: 'ticket-types' },
    { label: <Link to="/registry/price-list">Listino Prezzi</Link>, key: 'price-list' },
  ],
};

const doorQuotesItem = {
  key: 'quotes',
  label: <Link to="/quotes">Preventivi Porte</Link>,
  icon: React.createElement(PrinterOutlined),
};

const metalSheetQuotesItem = {
  key: 'metal-sheet-quotes',
  label: <Link to="/metal-sheet-quotes">Preventivi Lamiere</Link>,
  icon: React.createElement(PrinterOutlined),
};

const commissionsItem = {
  key: 'commissions',
  label: <Link to="/commissions">Commesse</Link>,
  icon: React.createElement(PrinterOutlined),
};

const ordersItem = {
  key: 'orders',
  label: <Link to="/orders">Ordini a Fornitore</Link>,
  icon: React.createElement(PrinterOutlined),
};

const incomingOrders = {
  key: 'incoming-orders',
  label: <Link to="/incoming-orders">Fornitori In Arrivo</Link>,
  icon: React.createElement(PrinterOutlined),
};

const warehouseItem = {
  key: 'warehouse',
  label: <Link to="/warehouse">Magazzino</Link>,
  icon: React.createElement(GroupOutlined),
};

const workOrdersItem = {
  key: 'work-orders',
  label: <Link to="/work-orders">Ordini di Lavoro</Link>,
  icon: React.createElement(GroupOutlined),
};

const laserCutItem = {
  key: 'laser-cut',
  label: <Link to="/laser-cut">Taglio Laser</Link>,
  icon: React.createElement(ToolOutlined),
};

const foldingItem = {
  key: 'folding',
  label: <Link to="/folding">Piegatura</Link>,
  icon: React.createElement(ToolOutlined),
};

const weldingItem = {
  key: 'welding',
  label: <Link to="/welding">Saldatura</Link>,
  icon: React.createElement(ToolOutlined),
};

const paintingItem = {
  key: 'painting',
  label: <Link to="/painting">Verniciatura</Link>,
  icon: React.createElement(ToolOutlined),
};

const assemblyItem = {
  key: 'assembly',
  label: <Link to="/assembly">Assemblaggio</Link>,
  icon: React.createElement(ToolOutlined),
};

const logoutItem = {
  key: 'logout',
  label: <Link to="/logout">Logout</Link>,
  icon: React.createElement(LoginOutlined),
};

const notificationsItem = {
  key: 'notifications',
  label: <Link to="/notifications">Notifiche <Badge count={4} /></Link>,
  icon: React.createElement(BellOutlined),
};

const workersItem = {
  key: 'workers',
  label: <Link to="/workers">Lavoratori</Link>,
  icon: React.createElement(UserOutlined),
};

const ticketsCustomer = {
  key: 'tickets',
  label: <Link to="/tickets">Le Mie Segnalazioni</Link>,
  icon: React.createElement(UserOutlined),
};

const ticketsManager = {
  key: 'tickets-manager',
  label: <Link to="/tickets-manager">Segnalazioni</Link>,
  icon: React.createElement(UserOutlined),
};

const energyItem = {
  key: 'energy',
  label: <Link to="/energy">Energia</Link>,
  icon: React.createElement(BulbOutlined),
};

const assetsItem = {
  key: 'assets',
  label: <Link to="/assets">Attrezzature</Link>,
  icon: React.createElement(ToolOutlined),
};

const administrationCommissionsItem = {
  key: 'administration/commissions',
  label: <Link to="/administration/commissions">Commesse</Link>,
  icon: React.createElement(PrinterOutlined),
};

export const SideNavigation = ({ collapsed, toggle }: { collapsed: boolean, toggle: () => void }) => {
  const location = useLocation();
  const userRoles = useAppSelector(userRolesState);
  const workCenters = useAppSelector(workCentersState);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const settingsItem = useMemo(() => {
    const settingsItem = {
      key: 'settings',
      label: <Typography>Impostazioni</Typography>,
      icon: React.createElement(SettingOutlined),
      children: [
        { label: <Link to="/settings/profile">Profilo</Link>, key: 'profile' },
      ],
    };

    if (userRoles.some(r => r.role === 'admin')) {
      settingsItem.children.push({ label: <Link to="/settings/users">Utenti</Link>, key: 'users' });
      settingsItem.children.push({ label: <Link to="/settings/workstation">Workstation</Link>, key: 'workstation' });
    }

    if (userRoles.some(r => r.role === 'energy-manager')) {
      settingsItem.children.push({ label: <Link to="/settings/iot-devices">Dispositivi IoT</Link>, key: 'iot-devices' });
    }

    return settingsItem;
  }, [userRoles]);

  const items: MenuProps['items'] = useMemo(() => {
    const items = [dashboardItem];

    if (userRoles.some(r => ['admin', 'administration', 'marketing', 'engineering'].includes(r.role))) {
      items.push(doorQuotesItem);
      items.push(metalSheetQuotesItem);
    }

    if (userRoles.some(r => ['admin', 'engineering'].includes(r.role))) {
      items.push(commissionsItem);
    }

    if (userRoles.some(r => ['admin', 'administration'].includes(r.role))) {
      items.push(administrationCommissionsItem);
    }

    if (userRoles.some(r => ['admin', 'engineering'].includes(r.role))) {
      items.push(workOrdersItem);
      items.push(warehouseItem);
      items.push(ordersItem);
      items.push(assetsItem);
    }

    if (userRoles.some(r => ['admin', 'worker'].includes(r.role))) {
      if (workCenters.includes('laser-cut')) {
        items.push(laserCutItem);
      }

      if (workCenters.includes('folding')) {
        items.push(foldingItem);
      }

      if (workCenters.includes('welding')) {
        items.push(weldingItem);
      }

      if (workCenters.includes('painting')) {
        items.push(paintingItem);
      }

      if (workCenters.includes('assembly')) {
        items.push(assemblyItem);
      }

      if (workCenters.includes('incoming-orders')) {
        items.push(incomingOrders);
      }
    }

    if (userRoles.some(r => ['tickets-customer'].includes(r.role))) {
      items.push(ticketsCustomer);
    }

    if (userRoles.some(r => ['tickets-manager'].includes(r.role))) {
      items.push(ticketsManager);
    }

    if (userRoles.some(r => r.role === 'energy-manager')) {
      items.push(energyItem);
    }

    if (userRoles.some(r => ['admin', 'administration', 'engineering'].includes(r.role))) {
      items.push(workersItem);
      items.push(registryItem);
    }

    items.push(settingsItem);

    items.push(logoutItem);

    return items;
  }, [userRoles]);

  const selectedKeys = useMemo(() => {
    const keys: string[] = [];

    if (location.pathname === '/') {
      keys.push('dashboard');
    } else {
      const rootPath = location.pathname.split('/')[1];
      const childPath = location.pathname.split('/')[2];

      const rootItem = items.find(i => i?.key === rootPath);

      const childItems = [
        ...registryItem.children,
        ...settingsItem.children,
      ];

      const childItem = childItems.find(i => i.key === childPath);

      if (rootItem?.key && typeof rootItem.key === 'string') {
        keys.push(rootItem.key);
      }

      if (childItem?.key) {
        keys.push(childItem.key);
      }
    }

    return keys;
  }, [location.pathname]);

  return (
    <Sider
      style={{ background: colorBgContainer }}
      breakpoint="lg"
      collapsedWidth="0"
      collapsible
      collapsed={collapsed}
      // onClick={toggle}
      // onBreakpoint={broken => console.log(broken)}
      // onCollapse={(collapsed, type) => console.log(collapsed, type)}
      trigger={null}
    >
      <Row justify="center" style={{ margin: '24px 0', visibility: collapsed ? 'hidden' : 'visible' }}>
        <img width={160} src={logo} alt="logo abruzzo lamiere" />
      </Row>

      <Menu mode="inline" selectedKeys={selectedKeys} items={items} style={{ border: 'none' }} />
    </Sider>
  );
};
