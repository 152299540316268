import { useSimpleQuery } from '@api/utils';
import { CreateDeviceBody, IotDevice } from '@api/types';
import { QueryKey } from '@constants/queries';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export function useGetDevices() {
  return useSimpleQuery<IotDevice[]>(
    [QueryKey.GetDevices],
    `${apiBaseUrl}/${ApiPath.Iot}/devices`,
  );
}

export function useCreateDevice() {
  return useMutation<AxiosResponse, AxiosError<{ message: string }>, CreateDeviceBody>({
    mutationKey: [QueryKey.CreateDevice],
    mutationFn: (body: CreateDeviceBody) => axios.post(`${apiBaseUrl}/${ApiPath.Iot}/devices`, body),
  });
}
