import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

interface AuthState {
  accessToken: string | null;
  isLoggedIn: boolean | null;
}

const initialState: AuthState = {
  accessToken: null,
  isLoggedIn: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string | null>) => {
      state.accessToken = action.payload;
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
  },
});

export const { setAccessToken, setIsLoggedIn } = authSlice.actions;

export const accessTokenState = (state: RootState) => state.auth.accessToken;
export const isLoggedInState = (state: RootState) => state.auth.isLoggedIn;

export default authSlice.reducer;
