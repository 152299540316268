import { Form, Input, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { closeModal, customerModalsState, selectedCustomerState, setSelectedCustomer } from '@store/customers';
import { useCreateCustomerPhoneNumber } from '@api/customers';
import { CustomerPhoneNumber } from '@api/types';
import { useGetCustomer } from '@api/customers/useGetCustomer';
import { useEffect } from 'react';

export function CreatePhoneNumberModal() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { createCustomerPhoneNumber: open } = useAppSelector(customerModalsState);
  const customer = useAppSelector(selectedCustomerState);

  const { mutate, isSuccess, reset } = useCreateCustomerPhoneNumber()
  const { refetch } = useGetCustomer(customer?.id ?? '');

  const close = () => {
    form.resetFields();
    dispatch(setSelectedCustomer(null));
    dispatch(closeModal('createCustomerPhoneNumber'));
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      reset();
      refetch();
      close()
    }
  }, [isSuccess]);

  const onOk = () => {
    form.submit();
  }

  const onFinish = (values: Pick<CustomerPhoneNumber, 'number' | 'note'>) => {
    if (customer) {
      mutate({
        customerId: customer.id,
        ...values,
      });
    }
  }

  return (
    <Modal title="Nuovo Recapito Telefonico" open={open} onOk={onOk} onCancel={close} styles={{ body: { paddingTop: 8, paddingBottom: 32 } }}>
      <Form name="create-customer-phone-number" form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="number" label="Numero" rules={[{ required: true, message: 'Per favore inserisci un numero valido' }]}>
          <Input />
        </Form.Item>

        <Form.Item name="note" label="note">
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
