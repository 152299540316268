import { Form, InputNumber, Modal, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { closeModal, commissionsModalsState, selectedCommissionState } from '@store/commissions';
import { useGetMaterials } from '@api/materials/useGetMaterials';
import { useAddCommissionMaterial } from '@api/commissions/useAddCommissionMaterial';
import { useGetCommissionMaterials } from '@api/commissions/useGetCommissionMaterials';
import { useEffect } from 'react';
import { AddCommissionMaterialBody } from '@api/types';

export function AddMaterialModal() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const commission = useAppSelector(selectedCommissionState);

  const { data: materials } = useGetMaterials();
  const { addMaterial: open } = useAppSelector(commissionsModalsState);

  const { mutate, isSuccess, reset } = useAddCommissionMaterial();
  const { refetch } = useGetCommissionMaterials(commission?.id ?? '');

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      reset();
      refetch();
      close();
    }
  }, [isSuccess]);

  const close = () => dispatch(closeModal('addMaterial'));

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => {
    form.resetFields();
    close();
  };

  const onFinish = (values: Pick<AddCommissionMaterialBody, 'materialId' | 'quantity'>) => {
    mutate({
      commissionId: commission?.id ?? '',
      ...values,
    });
  };

  return (
    <Modal
      title="Aggiungi Materiale"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      styles={{ body: { paddingTop: 8, paddingBottom: 32 } }}
    >
      <Form form={form} name="add-commission-material" layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="materialId"
          label="Materiale"
          rules={[{ required: true, message: 'Per favore seleziona un materiale' }]}
        >
          <Select
            showSearch
            optionFilterProp="label"
            options={materials?.map(m => ({ value: m.id, label: m.description }))}
          />
        </Form.Item>

        <Form.Item
          name="quantity"
          label="Quantità"
          rules={[{ required: true, message: 'Per favore inserisci una quantità' }]}
        >
          <InputNumber min={1} style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
