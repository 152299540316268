import { Button, Card, Col, Row, Space, Spin, Table, Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { CreateOrderModal } from './components/CreateOrderModal';
import { IncomingOrder } from '@api/types';
import { useNavigate } from 'react-router-dom';
import { useGetIncomingOrders } from '@api/orders';
import { format } from 'date-fns';
import { DateFormat } from '@constants/constants';

export function IncomingOrdersRoute() {
  const navigate = useNavigate();
  const { data: orders, isLoading: isLoadingOrders } = useGetIncomingOrders();

  const onCheck = (orderId: string) => {
    navigate(`/incoming-orders/${orderId}`);
  };

  const columns: ColumnProps<IncomingOrder>[] = [
    {
      title: 'Seriale',
      dataIndex: 'serial',
      key: 'serial',
      render: (_, record) => <Typography>OFABL{record.year}-{record.serial}</Typography>,
    },
    {
      title: 'Fornitore',
      dataIndex: 'supplier',
      key: 'supplier',
      render: (supplier) => <Typography>{supplier?.name ?? ''}</Typography>,
    },
    {
      title: 'Presunta Consegna',
      key: 'confirmedAt',
      render: (_, record) => {
        return record?.confirmedAt ? format(record.confirmedAt, DateFormat) : '';
      },
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onCheck(record.id)}>Controlla</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  if (isLoadingOrders) {
    return null;
  }

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card title="Ordini in Arrivo">
          <Col span={24}>
            <Spin spinning={isLoadingOrders}>
              <Table
                columns={columns}
                dataSource={orders}
                pagination={{
                  pageSizeOptions: ['10', '20', '30', '50'],
                  responsive: true,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
                  total: orders?.length ?? 0,
                }}
                rowKey="id"
                size="small"
              />
            </Spin>
          </Col>
        </Card>
      </Col>
      <CreateOrderModal />
    </Row>
  );
}
