import { Form } from 'antd';
import { useCreateTicketMessage, useUpdateCustomerTicket, useUpdateManagerTicket } from '@api/tickets';
import { useState } from 'react';

export function useTicket() {
  const [newMessageForm] = Form.useForm();
  const [editTicketForm] = Form.useForm();

  const [isOpenEditTicketModal, setIsOpenEditTicketModal] = useState(false);
  const [isOpenStatusModal, setIsOpenStatusModal] = useState(false);

  const {
    mutate: createTicketMessage,
    isPending: isPendingCreateTicketMessage,
    isSuccess: isSuccessCreateTicketMessage,
    reset: resetCreateTicketMessage,
  } = useCreateTicketMessage();

  const {
    mutate: updateCustomerTicket,
    isSuccess: isSuccessUpdateCustomerTicket,
    reset: resetUpdateCustomerTicket,
  } = useUpdateCustomerTicket();

  const {
    mutate: updateManagerTicket,
    isSuccess: isSuccessUpdateManagerTicket,
    reset: resetUpdateManagerTicket,
  } = useUpdateManagerTicket();

  return {
    createTicketMessage,
    editTicketForm,
    isOpenEditTicketModal,
    isOpenStatusModal,
    isPendingCreateTicketMessage,
    isSuccessCreateTicketMessage,
    isSuccessUpdateCustomerTicket,
    isSuccessUpdateManagerTicket,
    newMessageForm,
    resetCreateTicketMessage,
    resetUpdateCustomerTicket,
    resetUpdateManagerTicket,
    setIsOpenEditTicketModal,
    setIsOpenStatusModal,
    updateCustomerTicket,
    updateManagerTicket,
  }
}
