import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { Material } from '@api/types';

interface WarehouseModals {
  addMaterial: boolean;
  deleteMaterial: boolean;
  loadingMaterial: boolean;
}

interface WarehouseState {
  modals: WarehouseModals;
  selectedWarehouseId: string;
  selectedMaterial: Material | null;
}

const initialState: WarehouseState = {
  modals: {
    addMaterial: false,
    deleteMaterial: false,
    loadingMaterial: false,
  },
  selectedWarehouseId: '',
  selectedMaterial: null,
};

export const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = true;
    },
    closeModal: (state, action: PayloadAction<keyof typeof initialState.modals>) => {
      state.modals[action.payload] = false;
    },
    setSelectedWarehouseId: (state, action: PayloadAction<string>) => {
      state.selectedWarehouseId = action.payload;
    },
    setSelectedMaterial: (state, action: PayloadAction<Material | null>) => {
      state.selectedMaterial = action.payload;
    }
  },
});

export const { openModal, closeModal, setSelectedWarehouseId, setSelectedMaterial } = warehouseSlice.actions;

export const warehouseModalsState = (state: RootState) => state.warehouse.modals;
export const selectedWarehouseIdState = (state: RootState) => state.warehouse.selectedWarehouseId;
export const selectedMaterialState = (state: RootState) => state.warehouse.selectedMaterial;

export const isAddWarehouseMaterialModalOpenState = (state: RootState) => state.warehouse.modals.addMaterial;
export const isLoadingWarehouseMaterialModalOpenState = (state: RootState) => state.warehouse.modals.loadingMaterial;

export default warehouseSlice.reducer;
