import { Form, Input, Modal, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { assetsModalsState, closeModal } from '@store/assets';
import { useCreateMaintenanceActivity, useGetAssetMaintenanceActivities } from '@api/assets';
import { CreateMaintenanceActivityBody } from '@api/types/assets';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export function CreateMaintenanceActivityModal() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const [form] = Form.useForm();
  const { createActivity: open } = useAppSelector(assetsModalsState);

  const { mutate, isSuccess, reset } = useCreateMaintenanceActivity();
  const { refetch } = useGetAssetMaintenanceActivities(params?.assetId ?? '', params?.maintenanceId ?? '');

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      reset();
      refetch();
      close();
    }
  }, [isSuccess]);

  const close = () => dispatch(closeModal('createActivity'));

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => {
    close();
  };

  const onFinish = (values: CreateMaintenanceActivityBody) => {
    mutate({ ...values, assetId: params?.assetId ?? '', maintenanceId: params?.maintenanceId ?? '' });
  };

  const typeOption = [
    { value: 'scheduled', label: 'Programmato' },
    { value: 'unscheduled', label: 'Non Programmato' },
    { value: 'failure', label: 'Guasto' },
  ];

  const resultOption = [
    { value: 'ok', label: 'Esito Positivo' },
    { value: 'repair', label: 'Riparato' },
  ];

  return (
    <Modal
      title="Nuovo Invervento"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      styles={{ body: { paddingTop: 8, paddingBottom: 32 } }}
    >
      <Form name="create-maintenance-activity" form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="type"
          label="Tipo"
          rules={[{ required: true, message: 'Inserisci un tipo valido' }]}
          initialValue="scheduled"
        >
          <Select options={typeOption} />
        </Form.Item>

        <Form.Item
          name="result"
          label="Resultato"
          rules={[{ required: true, message: 'Inserisci un risultato valido' }]}
        >
          <Select options={resultOption} />
        </Form.Item>

        <Form.Item name="note" label="Note">
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
