import React from 'react';
import { Modal, Typography } from 'antd';
import { ModalProps } from 'antd/es/modal/interface';

export function ConfirmModal(props: ModalProps & { message?: string, subject?: string }) {
  return (
    <Modal {...props} styles={{ ...props.styles, body: { paddingTop: 8, paddingBottom: 32 } }}>
      {props.message && (
        <Typography style={{ marginBottom: 8 }}>{props.message}</Typography>
      )}
      {props.subject && (
        <Typography.Title level={5} style={{ textAlign: 'center' }}>{props.subject}</Typography.Title>
      )}
      {props.children}
    </Modal>
  );
}
