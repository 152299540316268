import { differenceInBusinessDays, isAfter } from 'date-fns';

interface Colors {
  green: string;
  orange: string;
  red: string;
}

export function getColorBasedOnBusinessDays(
  arrivalDate: Date,
  today: Date,
  warnDays: number = 3,
  colors: Colors = { green: 'green', orange: 'orange', red: 'red' },
) {
  if (isAfter(today, arrivalDate)) {
    // today is after the arrival date
    return colors.red;
  }

  // calculate the number of business days between today and the arrival date
  const businessDaysDiff = differenceInBusinessDays(arrivalDate, today);

  if (businessDaysDiff <= warnDays) {
    // within 3 business days before the arrival date
    return colors.orange;
  }

  // more than 3 business days before the arrival date
  return colors.green;
}
