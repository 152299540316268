import { useGetProfile } from '@api/users';
import { useGetUserRolesQuery } from '@api/roles';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { setIsLoading, setProfile, setRoles, userIsLoadingState } from '@store/user';
import { useAxiosInterceptor } from '@hooks/useAxiosInterceptor';
import useSocket from '@hooks/useSocket';
import { isLoggedInState } from '@store/auth';

export function useInitApp() {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(userIsLoadingState);

  const { isLoading: isLoadingInterceptors } = useAxiosInterceptor();
  const isLoggedIn = useAppSelector(isLoggedInState);

  const { contextHolder } = useSocket();

  const profile = useGetProfile();
  const roles = useGetUserRolesQuery(profile.data?.id ?? '');

  console.log('profile', profile);
  console.log('roles', roles);

  useEffect(() => {
    if (!isLoadingInterceptors && isLoggedIn) {
      if (!roles.isPending && !profile.isPending) {
        if (roles.isSuccess && Array.isArray(roles.data)) {
          dispatch(setRoles(roles.data));
        }

        if (profile.isSuccess && profile.data) {
          dispatch(setProfile(profile.data));
        }

        dispatch(setIsLoading(false));
      }
    }

    if (!isLoadingInterceptors && !isLoggedIn) {
      dispatch(setIsLoading(false));
    }
  }, [roles, profile]);

  return {
    isLoading: isLoading || isLoadingInterceptors,
    contextHolder,
  };
}
