import { Card, Col, Row } from 'antd';

export function Energy() {
  return (
    <Row>
      <Col xs={24}>
        <Card title="Consumi Elettrici">

        </Card>
      </Col>
    </Row>
  )
}
