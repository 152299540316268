import { UserProfileResponse, UserRole } from '@api/types';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

interface UserState {
  roles: UserRole[];
  profile: UserProfileResponse | null;
  isLoading: boolean;
}

const initialState: UserState = {
  roles: [],
  profile: null,
  isLoading: true,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setRoles: (state, action: PayloadAction<UserRole[]>) => {
      state.roles = action.payload;
    },
    setProfile: (state, action: PayloadAction<UserProfileResponse>) => {
      state.profile = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    }
  },
});

export const { setRoles, setProfile, setIsLoading } = userSlice.actions;

export const userRolesState = (state: RootState) => state.user.roles;
export const isAdministrationUserState = (state: RootState) => state.user.roles.some(r => r.role === 'administration');

export const userProfileState = (state: RootState) => state.user.profile;
export const userIsLoadingState = (state: RootState) => state.user.isLoading;

export default userSlice.reducer;
