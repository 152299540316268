import { Button, Card, Col, Input, Row, Space, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { ColumnProps } from 'antd/es/table';
import { useGetMaterials } from '@api/materials/useGetMaterials';
import { useNavigate } from 'react-router-dom';
import { Material } from '@api/types';
import { useGetWarehouses } from '@api/warehouses';
import { openModal, setSelectedWarehouseId } from '@store/warehouse';
import { useAppDispatch } from '@hooks/redux';
import { AddWarehouseMaterialModal } from '@routes/warehouse/components/AddWarehouseMaterialModal';

export function Materials() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState('');
  const { data, isLoading, refetch } = useGetMaterials();

  const { data: warehouses, isSuccess: isSuccessLoadingWarehouses } = useGetWarehouses();

  useEffect(() => {
    if (isSuccessLoadingWarehouses) {
      dispatch(setSelectedWarehouseId(warehouses?.[0].id));
    }
  }, [isSuccessLoadingWarehouses]);

  const onNew = () => {
    dispatch(openModal('addMaterial'));
  };

  const onEdit = (material: Material) => {
    navigate(`/registry/materials/${material.id}`);
  };

  const columns: ColumnProps<Material>[] = [
    {
      title: 'Descrizione',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  const tableRows = data?.filter(d => d.description.toLowerCase().includes(searchValue.toLowerCase())) ?? [];

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          title="Materiali"
          extra={<Button type="primary" onClick={onNew}>Nuovo Materiale</Button>}
        >
          <Row justify="end" style={{ marginTop: '24px', marginBottom: '36px' }}>
            <Col>
              <Space size="middle">
                <Input.Search
                  placeholder="descrizione"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  // onSearch={onSearch}
                  enterButton
                  style={{ minWidth: '300px' }}
                />
                <Button onClick={() => setSearchValue('')}>Reset</Button>
              </Space>
            </Col>
          </Row>

          <Spin spinning={isLoading}>
            <Table
              columns={columns}
              dataSource={tableRows}
              onRow={(row) => {
                return {
                  onClick: () => onEdit(row),
                  style: { cursor: 'pointer' },
                };
              }}
              pagination={{
                pageSize: 20,
                pageSizeOptions: ['10', '20', '30', '50'],
                responsive: true,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
                total: tableRows?.length ?? 0,
              }}
              rowKey="id"
              size="small"
            />
          </Spin>
        </Card>
      </Col>

      <AddWarehouseMaterialModal onSuccess={() => refetch()} />
    </Row>
  );
}
