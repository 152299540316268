import { Form, Input, InputNumber, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { assetsModalsState, closeModal, selectedAssetIdState } from '@store/assets';
import { useCreateMaintenance, useGetAssetMaintenances } from '@api/assets';
import { CreateMaintenanceBody } from '@api/types/assets';
import { useEffect } from 'react';

export function CreateMaintenanceModal() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { createMaintenance: open } = useAppSelector(assetsModalsState);
  const assetId = useAppSelector(selectedAssetIdState);

  const { mutate, isSuccess, reset } = useCreateMaintenance();
  const { refetch } = useGetAssetMaintenances(assetId);

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      reset();
      refetch();
      close();
    }
  }, [isSuccess]);

  const close = () => dispatch(closeModal('createMaintenance'));

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => {
    close();
  };

  const onFinish = (values: CreateMaintenanceBody) => {
    mutate({ ...values, assetId, interval: `${values.interval} months` });
  };

  return (
    <Modal open={open} onOk={onOk} onCancel={onCancel} styles={{ body: { paddingTop: 8, paddingBottom: 32 } }}>
      <Form name="create-asset" form={form} onFinish={onFinish} layout="vertical">
        <Form.Item name="name" label="Nome" rules={[{ required: true, message: 'Inserisci un nome valido' }]}>
          <Input />
        </Form.Item>

        <Form.Item name="description" label="Descrizione">
          <Input.TextArea rows={5} />
        </Form.Item>

        <Form.Item
          name="interval"
          label="Intervallo mensile"
          rules={[{ required: true, message: 'Inserisci un intervallo valido' }]}
        >
          <InputNumber min={1} style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
