import { Button, Card, Col, Input, Row, Space, Table, TableColumnProps, TableProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Commission } from '@api/types';
import { Link, useNavigate } from 'react-router-dom';
import { getCommissionStatusLabel } from '@utils/getCommissionsStatusLabel';
import { useExportCsv, useGetCommissions } from '@api/administration';

export function AdministrationCommissions() {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  console.log('selectedRowKeys', selectedRowKeys);

  const { data: commissions, refetch } = useGetCommissions(searchValue);
  const { mutate: exportCsv, isSuccess, reset } = useExportCsv();

  useEffect(() => {
    if (isSuccess) {
      reset();
      setSelectedRowKeys([]);
      refetch();
    }
  });

  const columns: TableColumnProps<Commission>[] = [
    {
      title: 'Seriale',
      dataIndex: 'serial',
      key: 'serial',
      render: (_, record) => <Link
        onClick={e => e.stopPropagation()}
        to={`/commissions/${record.id}`}
      >{record.serial}</Link>,
    },
    {
      title: 'Preventivo',
      dataIndex: 'quotation',
      key: 'quotation',
      render: (_, record) => <Link
        onClick={e => e.stopPropagation()}
        to={`/quotes/${record.quotation.id}`}
      >{record.quotation.serial}</Link>,
    },
    {
      title: 'Cliente',
      key: 'customer',
      render: (_, record) => record.quotation.customer?.name ?? '',
    },
    {
      title: 'Riferimento',
      key: 'reference',
      render: (_, record) => record.quotation.reference,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <Typography>{getCommissionStatusLabel(text)}</Typography>,
    },
    {
      title: 'Esportata',
      dataIndex: 'exported',
      key: 'exported',
      render: (_, record) => record.exports.csv ? 'Si' : 'No',
    },
  ];

  const rowSelection: TableProps<Commission>['rowSelection'] = {
    type: 'checkbox',
    onChange: (selectedRowKeys: React.Key[], selectedRows: Commission[]) => {
      setSelectedRowKeys(selectedRowKeys);
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    selectedRowKeys,
    getCheckboxProps: (record: Commission) => ({
      name: record.id,
    }),
  };

  return (
    <Row>
      <Col span={24}>
        <Card title="Commesse">
          <Row justify="space-between" style={{ marginTop: '24px', marginBottom: '36px' }}>
            <Button
              type="primary"
              onClick={() => exportCsv(selectedRowKeys)}
              disabled={selectedRowKeys.length === 0}
            >Esporta</Button>
            <Col>
              <Space size="middle">
                <Input.Search
                  placeholder="cerca"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  enterButton
                  style={{ minWidth: '300px' }}
                />
                <Button onClick={() => setSearchValue('')}>Reset</Button>
              </Space>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={commissions}
            rowSelection={rowSelection}
            onRow={(row) => {
              return {
                onClick: () => navigate(`/commissions/${row.id}`),
                style: { cursor: 'pointer' },
              };
            }}
            pagination={{
              pageSizeOptions: ['10', '20', '30', '50'],
              responsive: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
              total: commissions?.length ?? 0,
            }}
            rowKey="id"
          />
        </Card>
      </Col>
    </Row>
  );
}
