import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import { simplePost } from '@api/utils';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';

export function useGenerateCommissionOrders() {
  return useMutation({
    mutationKey: [QueryKey.GenerateCommissionOrders],
    mutationFn: (id: string) => simplePost(`${apiBaseUrl}/${ApiPath.Commissions}/${id}/orders`),
  })
}
