import { Breadcrumb, Button, Card, Col, Divider, Form, Input, List, Row, Select, Typography } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import { useGetCustomer } from '@api/customers/useGetCustomer';
import { useUpdateCustomer } from '@api/customers/useUpdateCustomer';
import { useCreateCustomer } from '@api/customers/useCreateCustomer';
import { useGetPaymentModes } from '@api/payment-modes';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { DeleteIconButton } from '@components/buttons/DeleteIconButton';
import { CustomerPhoneNumber } from '@api/types';
import { useAppDispatch } from '@hooks/redux';
import { openModal, setSelectedCustomer, setSelectedCustomerPhoneNumber } from '@store/customers';
import { CreatePhoneNumberModal } from '@routes/registry/customers/components/CreatePhoneNumberModal';
import { EditPhoneNumberModal } from '@routes/registry/customers/components/EditPhoneNumberModal';
import { DeletePhoneNumberModal } from '@routes/registry/customers/components/DeletePhoneNumberModal';

const customerTypeOptions = [{ value: 'business', label: 'Business' }, { value: 'private', label: 'Privato' }];

export function Customer() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const [customerId, setCustomerId] = useState<string>('');
  const [form] = useForm();
  const [type, setType] = useState<'business' | 'private'>('business');

  const {
    data: customer,
    isSuccess: isSuccessCustomer,
    refetch: refetchCustomer,
  } = useGetCustomer(customerId);
  const { mutate: updateCustomer, isSuccess: isSuccessUpdateCustomer } = useUpdateCustomer();
  const {
    mutate: createCustomer,
    isSuccess: isSuccessCreateCustomer,
    data: createCustomerData,
    reset: createCustomerReset,
  } = useCreateCustomer();

  const { data: paymentModes } = useGetPaymentModes();

  useEffect(() => {
    if (customer?.type) {
      setType(customer.type);
    }

    setCustomerId(params.id && params.id !== 'new' ? params.id : '');
  }, [customer?.type, params.id]);

  useEffect(() => {
    if (isSuccessUpdateCustomer) {
      refetchCustomer();
    }
  }, [isSuccessUpdateCustomer]);

  useEffect(() => {
    if (isSuccessCustomer && customer) {
      setType(customer.type);
      form.setFieldsValue(customer);
    }
  }, [isSuccessCustomer, customer, form]);

  useEffect(() => {
    if (isSuccessCreateCustomer) {
      createCustomerReset();

      navigate('/registry/customers/' + createCustomerData.data.id, { replace: true });
    }
  }, [isSuccessCreateCustomer]);

  const onFinish = (values: any) => {
    if (params.id === 'new') {
      createCustomer(values);
    } else {
      updateCustomer({ id: params.id, ...values });
    }
  };

  const onTypeChange = (value: 'business' | 'private') => {
    setType(value);
  };

  const onNewPhoneNumber = () => {
    if (customer) {
      dispatch(setSelectedCustomer(customer));
      dispatch(openModal('createCustomerPhoneNumber'));
    }
  };

  const onEditPhoneNumber = (phoneNumber: CustomerPhoneNumber) => {
    if (customer) {
      dispatch(setSelectedCustomer(customer));
      dispatch(setSelectedCustomerPhoneNumber(phoneNumber));
      dispatch(openModal('editCustomerPhoneNumber'));
    }
  };

  const onDeletePhoneNumber = (phoneNumber: CustomerPhoneNumber) => {
    if (customer) {
      dispatch(setSelectedCustomer(customer));
      dispatch(setSelectedCustomerPhoneNumber(phoneNumber));
      dispatch(openModal('deleteCustomerPhoneNumber'));
    }
  };

  const items = [
    { title: <Link to="/registry/customers">Clienti</Link> },
    { title: customer ? customer.type === 'business' ? customer.name : `${customer.firstName ? customer.firstName + ' ' : ''}${customer.lastName}` : 'Nuovo Cliente' },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card
          title={<Breadcrumb items={items} />}
          extra={<Button type="primary" htmlType="submit" form="customer">Salva</Button>}
        >
          <Col span={24}>
            <Typography.Title level={3}>Dati Anagrafici</Typography.Title>
          </Col>

          <Form form={form} name="customer" layout="vertical" onFinish={onFinish}>
            <Row gutter={16}>
              <Col span={24} md={12} lg={6}>
                <Form.Item name="type" label="Tipologia" initialValue={customer?.type ?? 'business'}>
                  <Select options={customerTypeOptions} onChange={onTypeChange} />
                </Form.Item>
              </Col>
            </Row>

            {type === 'business' && (
              <Row gutter={16}>
                <Col span={24} md={12} lg={6}>
                  <Form.Item name="name" label="Nome Azienda" initialValue={customer?.name}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={24} md={12} lg={6}>
                  <Form.Item name="vatNumber" label="Partita IVA" initialValue={customer?.vatNumber}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={24} md={12} lg={6}>
                  <Form.Item name="taxCode" label="Codice Fiscale" initialValue={customer?.taxCode}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={24} md={12} lg={6}>
                  <Form.Item name="sdi" label="SDI" initialValue={customer?.sdi}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            )}

            {type === 'private' && (
              <Row gutter={16}>
                <Col span={24} md={12} lg={6}>
                  <Form.Item name="firstName" label="Nome" initialValue={customer?.firstName}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={24} md={12} lg={6}>
                  <Form.Item name="lastName" label="Cognome" initialValue={customer?.lastName}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={24} md={12} lg={6}>
                  <Form.Item name="taxCode" label="Codice Fiscale" initialValue={customer?.taxCode}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row gutter={16}>
              <Col span={24} md={12} lg={6}>
                <Form.Item name="email" label="Email" initialValue={customer?.email}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={12} lg={6}>
                <Form.Item name="pec" label="Pec" initialValue={customer?.pec}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} style={{ marginBottom: 16 }}>
              <Col span={24}>
                <List
                  header={<Typography.Title level={3}>Recapiti Telefonici <Button
                    type="text"
                    onClick={onNewPhoneNumber}
                  ><PlusOutlined /></Button></Typography.Title>}
                  dataSource={customer?.phoneNumbers}
                  renderItem={(item) => (
                    <List.Item
                      actions={[
                        <Button type="text" onClick={() => onEditPhoneNumber(item)}><EditOutlined /></Button>,
                        <DeleteIconButton onClick={() => onDeletePhoneNumber(item)} />,
                      ]}
                    >
                      {item?.number} {item?.note ?? ''}
                    </List.Item>
                  )}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Typography.Title level={3}>Sede Legale</Typography.Title>
              </Col>
              <Col span={24} md={12} lg={6}>
                <Form.Item name="legalAddress" label="Indirizzo" initialValue={customer?.legalAddress}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={12} lg={6}>
                <Form.Item name="legalCity" label="Città" initialValue={customer?.legalCity}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={12} lg={6}>
                <Form.Item name="legalProvince" label="Provincia" initialValue={customer?.legalProvince}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={12} lg={6}>
                <Form.Item name="legalPostalCode" label="CAP" initialValue={customer?.legalPostalCode}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={12} lg={6}>
                <Form.Item name="legalCountry" label="Nazione" initialValue={customer?.legalCountry}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <Row gutter={16}>
              <Col span={24}>
                <Typography.Title level={3}>Sede Operativa</Typography.Title>
              </Col>
              <Col span={24} md={12} lg={6}>
                <Form.Item name="address" label="Indirizzo" initialValue={customer?.address}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={12} lg={6}>
                <Form.Item name="city" label="Città" initialValue={customer?.city}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={12} lg={6}>
                <Form.Item name="province" label="Provincia" initialValue={customer?.province}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={12} lg={6}>
                <Form.Item name="postalCode" label="CAP" initialValue={customer?.postalCode}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={12} lg={6}>
                <Form.Item name="country" label="Nazione" initialValue={customer?.country}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <Row gutter={16}>
              <Col span={24}>
                <Typography.Title level={3}>Pagamento</Typography.Title>
              </Col>
              <Col span={24} md={12} lg={6}>
                <Form.Item name="paymentModeId" label="Modalità" initialValue={customer?.paymentModeId}>
                  <Select options={paymentModes?.map(pm => ({ label: pm.name, value: pm.id }))} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>

      <CreatePhoneNumberModal />
      <EditPhoneNumberModal />
      <DeletePhoneNumberModal />
    </Row>
  );
}
