import React, { useEffect } from 'react';
import { useDeleteOrderMaterial } from '@api/orders/useDeleteOrderMaterial';
import { useGetOrderMaterials } from '@api/orders/useGetOrderMaterials';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  closeModal,
  isDeleteOrderMaterialModalOpenState,
  selectedOrderMaterialState,
  setSelectedOrderMaterial,
} from '@store/orders';
import { ConfirmModal } from '@components/modals/ConfirmModal';

export function DeleteOrderMaterialModal() {
  const dispatch = useAppDispatch();
  const open = useAppSelector(isDeleteOrderMaterialModalOpenState);
  const orderMaterial = useAppSelector(selectedOrderMaterialState);
  const { mutate: deleteOrderMaterial, isSuccess: isSuccessDeleteOrderMaterial } = useDeleteOrderMaterial();
  const { refetch: refetchOrderMaterials } = useGetOrderMaterials(orderMaterial?.orderId ?? '');

  useEffect(() => {
    if (isSuccessDeleteOrderMaterial) {
      refetchOrderMaterials();
      dispatch(closeModal('deleteOrderMaterial'));
      dispatch(setSelectedOrderMaterial(null));
    }
  }, [isSuccessDeleteOrderMaterial]);

  const onOk = () => {
    deleteOrderMaterial({
      orderId: orderMaterial?.orderId ?? '',
      materialId: orderMaterial?.materialId ?? '',
    });
  };

  const onCancel = () => {
    dispatch(closeModal('deleteOrderMaterial'));
    dispatch(setSelectedOrderMaterial(null));
  };

  return (
    <ConfirmModal
      title="Aggiungi Materiale"
      message="Sei sicuro di voler cancellare il seguente materiale?"
      subject={orderMaterial?.description}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      okType="danger"
      okText="Rimuovi"
    />
  );
}
