import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, Modal, Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { TicketStatus as TicketStatusType, useDeleteCustomerTicketMessage, useGetManagerTicket } from '@api/tickets';
import { TicketInfo } from '@routes/tickets/components/TicketInfo';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import { DateFormat, DatetimeFormat } from '@constants/constants';
import { getWorkOrderStatusLabel } from '@routes/work-orders/utils';
import { ConfirmModal } from '@components/modals/ConfirmModal';
import { useTicket } from '@routes/tickets/hooks/useTicket';
import { Ticket, TicketMessage } from '@api/types';

export function TicketManager() {
  const params = useParams();

  const {
    createTicketMessage,
    editTicketForm,
    isOpenEditTicketModal,
    isOpenStatusModal,
    isPendingCreateTicketMessage,
    isSuccessCreateTicketMessage,
    isSuccessUpdateManagerTicket,
    newMessageForm,
    resetCreateTicketMessage,
    resetUpdateManagerTicket,
    setIsOpenEditTicketModal,
    setIsOpenStatusModal,
    updateManagerTicket,
  } = useTicket();

  const [selectedTicketMessageId, setSelectedTicketMessageId] = useState('');
  const [isOpenDeleteTicketMessageModal, setIsOpenDeleteTicketMessageModal] = useState(false);

  const { data: ticket, refetch: refetchTicket } = useGetManagerTicket(params?.id ?? '');

  const {
    mutate: deleteCustomerTicketMessage,
    isSuccess: isSuccessDeleteCustomerTicketMessage,
    reset: resetDeleteCustomerTicketMessage,
  } = useDeleteCustomerTicketMessage();

  useEffect(() => {
    if (isSuccessDeleteCustomerTicketMessage) {
      setSelectedTicketMessageId('');
      resetDeleteCustomerTicketMessage();
      setIsOpenDeleteTicketMessageModal(false);
      refetchTicket();
    }
  }, [isSuccessDeleteCustomerTicketMessage]);

  useEffect(() => {
    if (isSuccessCreateTicketMessage) {
      resetCreateTicketMessage();
      newMessageForm.resetFields();
      refetchTicket();
    }
  }, [isSuccessCreateTicketMessage]);

  useEffect(() => {
    if (isSuccessUpdateManagerTicket) {
      resetUpdateManagerTicket();
      setIsOpenStatusModal(false);
      setIsOpenEditTicketModal(false);
      refetchTicket();
    }
  }, [isSuccessUpdateManagerTicket]);

  const onFinishNewMessage = (values: Pick<TicketMessage, 'text'>) => {
    createTicketMessage({
      ticketId: ticket?.id ?? '',
      text: values.text,
    });
  };

  const onMessageDelete = (id: string) => {
    setSelectedTicketMessageId(id);
    setIsOpenDeleteTicketMessageModal(true);
  };

  const onOkEditTicket = () => {
    editTicketForm.submit();
  };

  const onCancelEditTicket = () => {
    setIsOpenEditTicketModal(false);
  };

  const onFinishEditTicket = (values: Pick<Ticket, 'subject'>) => {
    updateManagerTicket({
      id: ticket?.id ?? '',
      body: values,
    });
  };

  return (
    <Row>
      <TicketInfo title="Seriale" text={ticket?.serial ?? ''} />

      <TicketInfo
        title="Stato"
        text={ticket?.status === 'open' ? 'Aperta' : 'Chiusa'}
        icon={<EditOutlined />}
        onClick={() => setIsOpenStatusModal(true)}
      />

      <TicketInfo title="Data Creazione" text={ticket?.createdAt ? format(ticket.createdAt, DateFormat) : ''} />

      <TicketInfo title="Ordine Di Lavoro" text={ticket?.workOrderSerial ?? ''} />

      <TicketInfo title="Commessa" text={ticket?.workOrderCommissionId ? ticket?.workOrderCommissionSerial : 'Tutte'} />

      <TicketInfo title="Centro di Lavoro" text={getWorkOrderStatusLabel(ticket?.workPhaseKey ?? '')} />

      <Col span={24}>
        <Card>
          <Row justify="space-between">
            <Typography.Text>Tipologia</Typography.Text>
          </Row>
          <Col>
            <Typography.Text
              style={{
                fontSize: 18,
              }}
            >{ticket?.typeName ?? ''}</Typography.Text>
          </Col>
        </Card>
      </Col>

      <Col span={24}>
        <Card>
          <Row justify="space-between">
            <Typography.Text>Oggetto</Typography.Text>
            <Button type="link" onClick={() => setIsOpenEditTicketModal(true)}><EditOutlined /></Button>
          </Row>
          <Col>
            <Typography.Text
              style={{
                fontSize: 18,
              }}
            >{ticket?.subject ?? ''}</Typography.Text>
          </Col>
        </Card>
      </Col>

      {ticket?.messages?.map(m => (
        <Col key={m.id} span={24}>
          <Card>
            <Row justify="space-between" style={{ marginBottom: 16 }}>
              <Typography.Text type="secondary">Da {m.user} il {format(ticket.createdAt, DatetimeFormat)}</Typography.Text>
              <Button type="link" onClick={() => onMessageDelete(m.id)}><DeleteOutlined /></Button>
            </Row>

            <Col>
              <Typography.Text style={{ whiteSpace: 'pre-wrap', fontSize: 16 }}>{m.text}</Typography.Text>
            </Col>
          </Card>
        </Col>
      ))}

      <Col span={24} style={{ marginTop: 16 }}>
        <Card>
          <Form form={newMessageForm} name="new-message" onFinish={onFinishNewMessage} layout="vertical">
            <Col style={{ marginBottom: 16 }}>
              <Form.Item
                name="text"
                label="Nuovo Messaggio"
                required={false}
                rules={[{ required: true, message: 'Inserisci un messaggio valido' }]}
              >
                <Input.TextArea rows={3} disabled={isPendingCreateTicketMessage} />
              </Form.Item>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" form="new-message">Invia</Button>
            </Col>
          </Form>
        </Card>
      </Col>

      <ConfirmModal
        open={isOpenStatusModal}
        message={
          ticket?.status === TicketStatusType.Open
            ? 'Sei sicuro di voler chiudere il ticket?'
            : 'Sei sicuro di vole riaprire il ticket?'
        }
        title={
          ticket?.status === TicketStatusType.Open
            ? 'Chiudi Ticket'
            : 'Riapri Ticket'
        }
        onOk={() => {
          updateManagerTicket({
            id: ticket?.id ?? '',
            body: { status: ticket?.status === 'open' ? TicketStatusType.Closed : TicketStatusType.Open },
          });
        }}
        onCancel={() => {
          setIsOpenStatusModal(false);
        }}
      />

      <ConfirmModal
        open={isOpenDeleteTicketMessageModal}
        title="Cancella Messaggio"
        message="Sei sicuro di voler cancellare il messaggio?"
        onOk={() => {
          deleteCustomerTicketMessage({
            ticketId: ticket?.id ?? '',
            messageId: selectedTicketMessageId,
          });
        }}
        onCancel={() => {
          setIsOpenDeleteTicketMessageModal(false);
        }}
      />

      <Modal
        title="Modifica Segnalazione"
        open={isOpenEditTicketModal}
        onOk={onOkEditTicket}
        onCancel={onCancelEditTicket}
      >
        <Form name="edit-ticket" form={editTicketForm} onFinish={onFinishEditTicket} layout="vertical">
          <Form.Item name="subject" label="Oggetto" initialValue={ticket?.subject ?? ''}>
            <Input.TextArea rows={10} />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
}
