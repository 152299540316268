import React from 'react';
import { Card, Checkbox, Col, Row, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { allWorkCenters, setWorkCenters, WorkCenter, workCentersState } from '@store/config';

function getWorkCenterLabel(key: string): string {
  switch (key) {
    case 'laser-cut':
      return 'Taglio Laser';
    case  'folding':
      return 'Piegatura';
    case 'welding':
      return 'Saldatura';
    case  'painting':
      return 'Verniciatura';
    case  'assembly':
      return 'Assemblaggio';
    default:
      return key;
  }
}

export function WorkstationRoute() {
  const dispatch = useAppDispatch();
  const workCenters = useAppSelector(workCentersState);

  return (
    <Row>
      <Col span={24}>
        <Card title="Workstation">
          <Row>
            <Col>
              <Typography.Title level={3} style={{ marginTop: 0 }}>Centri di Lavoro</Typography.Title>
              <Checkbox.Group
                options={allWorkCenters.map(w => ({ label: getWorkCenterLabel(w), value: w }))}
                defaultValue={workCenters}
                onChange={(values: WorkCenter[]) => dispatch(setWorkCenters(values))}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}
