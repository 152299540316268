import { Form, Input, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { closeModal, isCreateAttributeModalOpenState, selectedAssetIdState } from '@store/assets';
import { CreateAttributeBody } from '@api/types/assets';
import { useCreateAssetAttribute, useGetAssetAttributes } from '@api/assets';
import { useEffect } from 'react';

export function CreateAttributeModal() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const open = useAppSelector(isCreateAttributeModalOpenState);
  const selectedAssetId = useAppSelector(selectedAssetIdState);

  const { mutate: createAssetAttribute, isSuccess, reset } = useCreateAssetAttribute();
  const { refetch } = useGetAssetAttributes(selectedAssetId);

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      reset();
      refetch();
      close();
    }
  }, [isSuccess]);

  const close = () => dispatch(closeModal('createAttribute'));

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => {
    close();
  };

  const onFinish = (values: Pick<CreateAttributeBody, 'name' | 'value'>) => {
    createAssetAttribute({
      assetId: selectedAssetId,
      ...values,
    });
  };

  return (
    <Modal open={open} onOk={onOk} onCancel={onCancel} styles={{ body: { paddingTop: 8, paddingBottom: 32 } }}>
      <Form name="create-attribute" form={form} onFinish={onFinish} layout="vertical">
        <Form.Item name="name" label="Nome" rules={[{ required: true, message: 'Inserisci un nome valido' }]}>
          <Input />
        </Form.Item>

        <Form.Item name="value" label="Valore" rules={[{ required: true, message: 'Inserisci un valore valido' }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
