import { useMutation, useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import {
  Asset,
  AssetAttribute,
  AssetMaintenance,
  AssetMaintenanceActivity,
  CreateAssetBody,
  CreateAttributeBody,
  CreateMaintenanceActivityBody,
  CreateMaintenanceBody,
  UpdateAssetBody,
} from '@api/types/assets';
import { simpleGet } from '@api/utils';

export function useGetAssets() {
  return useQuery({
    queryKey: [QueryKey.GetAssets],
    queryFn: () => simpleGet<Asset[]>(`${apiBaseUrl}/${ApiPath.Assets}`),
  });
}

export function useGetAsset(id: string) {
  return useQuery({
    queryKey: [QueryKey.GetAsset, id],
    queryFn: () => simpleGet<Asset>(`${apiBaseUrl}/${ApiPath.Assets}/${id}`),
    enabled: id !== '',
  });
}

export function useGetAssetAttributes(id: string) {
  return useQuery({
    queryKey: [QueryKey.GetAssetAttributes, id],
    queryFn: () => simpleGet<AssetAttribute[]>(`${apiBaseUrl}/${ApiPath.Assets}/${id}/attributes`),
    enabled: id !== '',
  });
}

export function useCreateAsset() {
  return useMutation({
    mutationKey: [QueryKey.CreateAsset],
    mutationFn: (data: CreateAssetBody) => axios.post(`${apiBaseUrl}/${ApiPath.Assets}`, data),
  });
}

export function useUpdateAsset() {
  return useMutation({
    mutationKey: [QueryKey.UpdateAsset],
    mutationFn: (data: UpdateAssetBody) => axios.put(`${apiBaseUrl}/${ApiPath.Assets}/${data.id}`, data),
  });
}

export function useCreateAssetAttribute() {
  return useMutation({
    mutationKey: [QueryKey.CreateAssetAttribute],
    mutationFn: (data: CreateAttributeBody) => axios.post(`${apiBaseUrl}/${ApiPath.Assets}/${data.assetId}/attributes`, data),
  });
}

export function useGetAssetMaintenances(id: string) {
  return useQuery({
    queryKey: [QueryKey.GetMaintenances, id],
    queryFn: () => simpleGet<AssetMaintenance[]>(`${apiBaseUrl}/${ApiPath.Assets}/${id}/maintenances`),
    enabled: id !== '',
  });
}

export function useGetAssetMaintenanceActivities(assetId: string, maintenanceId: string) {
  return useQuery({
    queryKey: [QueryKey.GetMaintenanceActivities, assetId, maintenanceId],
    queryFn: () => simpleGet<AssetMaintenanceActivity[]>(`${apiBaseUrl}/${ApiPath.Assets}/${assetId}/maintenances/${maintenanceId}/activities`),
    enabled: assetId !== '' && maintenanceId !== '',
  });
}

export function useGetAssetMaintenance(assetId: string, maintenanceId: string) {
  return useQuery({
    queryKey: [QueryKey.GetMaintenance, assetId, maintenanceId],
    queryFn: () => simpleGet<AssetMaintenance>(`${apiBaseUrl}/${ApiPath.Assets}/${assetId}/maintenances/${maintenanceId}`),
    enabled: assetId !== '' && maintenanceId !== '',
  });
}

export function useCreateMaintenance() {
  return useMutation({
    mutationKey: [QueryKey.CreateMaintenance],
    mutationFn: (data: CreateMaintenanceBody) => axios.post(`${apiBaseUrl}/${ApiPath.Assets}/${data.assetId}/maintenances`, data),
  });
}

export function useCreateMaintenanceActivity() {
  return useMutation({
    mutationKey: [QueryKey.CreateMaintenanceActivity],
    mutationFn: (data: CreateMaintenanceActivityBody) =>
      axios.post(`${apiBaseUrl}/${ApiPath.Assets}/${data.assetId}/maintenances/${data.maintenanceId}/activities`, data),
  });
}
