export function getNewDeviceErrorLabel(error: string) {
  switch (error) {
    case 'device_not_found':
      return 'Dispositivo non trovato';
    case 'existing_device':
      return 'Dispositivo esistente';
    default:
      return 'Impossibile aggiungere il dispositivo';
  }
}
