export enum Commissions {
  GetCommissions = 'GetCommissions',
  GetCommission = 'GetCommission',
  CreateCommission = 'CreateCommission',
  GetCommissionFiles = 'GetCommissionFiles',
  GetCommissionMaterials = 'GetCommissionMaterials',
  UpdateCommission = 'UpdateCommission',
  AddCommissionMaterial = 'AddCommissionMaterial',
  UpdateCommissionMaterial = 'UpdateCommissionMaterial',
  GenerateCommissionOrders = 'GenerateCommissionOrders',
}
