import { Form, Input, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { closeModal, isCreateAssetModalOpenState } from '@store/assets';
import { useCreateAsset, useGetAssets } from '@api/assets';
import { CreateAssetBody } from '@api/types/assets';
import { useEffect } from 'react';

export function CreateAssetModal() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const open = useAppSelector(isCreateAssetModalOpenState);

  const { mutate, isSuccess, reset } = useCreateAsset();
  const { refetch } = useGetAssets()

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      reset();
      refetch();
      close();
    }
  }, [isSuccess]);

  const close = () => dispatch(closeModal('createAsset'));

  const onOk = () => {
    form.submit();
  }

  const onCancel = () => {
    close();
  };

  const onFinish = (values: CreateAssetBody) => {
    mutate(values);
  }

  return (
    <Modal open={open} onOk={onOk} onCancel={onCancel} styles={{ body: { paddingTop: 8, paddingBottom: 32 } }}>
      <Form name="create-asset" form={form} onFinish={onFinish} layout="vertical">
        <Form.Item name="name" label="Nome" rules={[{ required: true, message: 'Inserisci un nome valido' }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
