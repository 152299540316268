import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';

export interface UpdateCommissionMaterialData {
  commissionId: string;
  commissionMaterialId: string;
  reserved: string;
}

export function useUpdateCommissionMaterial() {
  return useMutation({
    mutationKey: [QueryKey.UpdateCommissionMaterial],
    mutationFn: (data: UpdateCommissionMaterialData) => axios.put(`${apiBaseUrl}/${ApiPath.Commissions}/${data.commissionId}/materials/${data.commissionMaterialId}`, data),
  });
}
