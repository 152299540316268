import { Card, Col, Row } from 'antd';
import { useGetNotifications } from '@api/notifications';

export function Notifications() {
  const { data: notifications } = useGetNotifications();
  console.log('notifications', notifications);

  return (
    <Row>
      <Col span={24}>
        <Card title="Notifiche"></Card>
      </Col>
    </Row>
  );
}
