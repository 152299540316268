import { useGetWorkOrderPhaseCommission } from '@api/work-orders';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, Card, Col, Divider, Row, Spin, Table, TableColumnProps, Typography } from 'antd';
import { getWorkOrderStatusLabel } from '@routes/work-orders/utils';
import React from 'react';
import { apiBaseUrl } from '@constants/constants';
import { useGetCommissionFiles } from '@api/commissions/useGetCommissionFiles';
import { EntityFile } from '@api/types';

interface Props {
  workPhaseKey: string;
}

const InfoText = ({ title, text }: { title: string, text: string }) => (
  <Col span={8}>
    <Typography.Title level={4}>{title}</Typography.Title>
    <Typography.Text style={{ fontSize: 18 }}>{text}</Typography.Text>
  </Col>
);

export function WorkOrderPhaseCommissionRoute({ workPhaseKey }: Props) {
  const params = useParams();
  const { data: commission } = useGetWorkOrderPhaseCommission(workPhaseKey, params?.workOrderId ?? '', params?.workOrderCommissionId ?? '');
  const { data: files, isLoading: isLoadingFiles } = useGetCommissionFiles(commission?.commissionId ?? '');

  const items = [
    {
      title: <Link
        to={`/${workPhaseKey}`}
        style={{ marginInline: 'unset' }}
      >{getWorkOrderStatusLabel(workPhaseKey)}</Link>,
    },
    {
      title:
        <Link to={`/${workPhaseKey}/${commission?.workOrder?.id ?? ''}`}>WO{commission?.workOrder?.year ?? ''}-{commission?.workOrder?.serial ?? ''}</Link>,
    },
    { title: `CO${commission?.year ?? ''}-${commission?.serial ?? ''}` },
  ];

  const fileColumns: TableColumnProps<EntityFile>[] = [
    {
      title: 'Nome',
      key: 'originalName',
      render: (_, record) => <a
        href={`${apiBaseUrl}/files/${record.fileId}${record.extension ? `.${record.extension}` : ''}`}
        target="_blank"
        rel="noreferrer"
        style={{ fontSize: 18 }}
      >{record.originalName}</a>,
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card title={<Breadcrumb items={items} />}>
          <Row>
            <InfoText title="Seriale Commessa" text={`${commission?.year ?? ''}-${commission?.serial ?? ''}`} />

            <InfoText title="Cliente" text={commission?.quotation?.customer?.name ?? ''} />

            <InfoText title="Riferimento" text={commission?.quotation?.reference ?? ''} />

            {commission?.quotation?.product?.options?.map(o => (
              <InfoText
                key={o.key}
                title={o.name}
                text={o.params.filter(p => p.value !== null).map(p => `${p.name}: ${p.value}`).join(' ')}
              />
            ))}
          </Row>

          <Divider />

          <Typography.Title level={3}>Files</Typography.Title>
          <Spin spinning={isLoadingFiles}>
            <Table
              columns={fileColumns}
              showHeader={false}
              dataSource={files}
              pagination={{
                pageSizeOptions: ['10', '20', '30', '50'],
                responsive: true,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
                total: files?.length ?? 0,
              }}
              rowKey="id"
              size="small"
            />
          </Spin>
        </Card>
      </Col>
    </Row>
  );
}
